import {
    FormControl, InputAdornment,
    InputLabel, ListItemText,
    MenuItem,
    Select, SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {

    toggleSelectAllAction
} from "../../../../flux/segment/list/SegmentListActions";
import {SelectionModel} from "../../../../model/SelectionModel";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import SearchIcon from "@mui/icons-material/Search";
import MatchCaseAdornment from "./MatchCaseAdornment";
import SegmentListFilter from "../../../../flux/segment/list/SegmentListFilter";
import {NavigateFunction, useNavigate} from "react-router-dom";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {getSegmentPath} from "../../../../routes/EditorRoute";
import Immutable from "immutable";
import Project from "../../../../model/Project";
import {grey} from "@mui/material/colors";

export default function SegmentListControls() {
    const lastStep = projectPageStore
        .getState()
        .project
        .getFirstStep();
    const projectState = projectPageStore.getState();

    const [selection, setSelection] = useState(new SelectionModel());
    const [totalItems, setTotalItems] = useState(0);
    const [source, setSource] = useState("");
    const [target, setTarget] = useState("");
    const initialSegmentListFilterState = segmentListStore.getState();
    const [sourceMatchCase, setSourceMatchCase] = useState(false);
    const [targetMatchCase, setTargetMatchCase] = useState(false);
    const [selectedWorkflowSteps, setSelectedWorkflowSteps] = useState<Immutable.List<number>>(Immutable.List());
    const [currentProject, setCurrentProject]
        = useState<Project>(projectState.project);
    const [filter, setFilter]
        = useState(initialSegmentListFilterState.filter);
    const navigate = useNavigate();

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
        });
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            const workflowIds = state.filter.workflowIds;
            setSelection(state.selection);
            setTotalItems(state.totalsItems);
            setFilter(state.filter);
            setSelectedWorkflowSteps(workflowIds ? Immutable.List(workflowIds) : Immutable.List());
        });

        return () => {
            segmentListListener.remove();
            projectPageListener.remove();
        };
    });

    return (
        <Stack direction={"row"} spacing={1} sx={{padding: '0px'}}>
            {drawControls(selection, totalItems)}
            <Box
                display="flex"
                gap="15px"
                sx={{ flexGrow: 1 }}
            >
                <TextField
                    size="small"
                    fullWidth
                    placeholder="Search in Source"
                    onChange={event => setSource(event.target.value)}
                    value={source}
                    onBlur={() => handleSaveFilter(
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    onKeyDown={e => handelOnCompleteEdit(
                        e,
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: grey[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <MatchCaseAdornment
                                matchCase={sourceMatchCase}
                                setMatchCase={setSourceMatchCase}
                            />
                        ),
                        style: {height: '30px',},
                    }}
                    sx={{ flexGrow: 1 }}
                />
                <TextField
                    size="small"
                    fullWidth
                    placeholder="Search in Target"
                    onChange={event => setTarget(event.target.value)}
                    value={target}
                    onBlur={() => handleSaveFilter(
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    onKeyDown={e => handelOnCompleteEdit(
                        e,
                        filter,
                        source,
                        target,
                        sourceMatchCase,
                        targetMatchCase,
                        setSource,
                        setTarget,
                        setSourceMatchCase,
                        setTargetMatchCase,
                        navigate
                    )}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: grey[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <MatchCaseAdornment
                                matchCase={targetMatchCase}
                                setMatchCase={setTargetMatchCase}
                            />
                        ),
                        style: {height: '30px',},
                    }}
                    sx={{ flexGrow: 1 }}
                />
                <FormControl variant="outlined" style={{minWidth: '150px',marginRight:'40px'}}>
                    <InputLabel shrink>
                        Status
                    </InputLabel>
                    <Select sx={{height: '30px', lineHeight: '30px'}}
                            label="Status"
                            displayEmpty
                            multiple value={selectedWorkflowSteps.toArray()}
                            onChange={e => handleWorkflowFilterChanged(e, filter, navigate)}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return "All Statuses";
                                }
                                return selected.map(id => {
                                    if (!currentProject)
                                        return;
                                    const found = currentProject.workflow
                                        .find(step => step.id === Number(id));
                                    return !found ? id : found.name;
                                }).join(', ')
                            }} variant={'outlined'}>
                        {currentProject && currentProject.workflow.map(workflowStep =>
                            <MenuItem key={workflowStep.id} value={workflowStep.id}>
                                <Checkbox checked={
                                    !workflowStep.id
                                        ? false
                                        : selectedWorkflowSteps.indexOf(workflowStep.id) > -1
                                }/>
                                <ListItemText>{workflowStep.name}</ListItemText>
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Box>

        </Stack>
    )
}

function drawControls(selection: SelectionModel,
                      totalItems: number) {
    return (
        <Stack direction={"row"} spacing={0.5}>
            <Box display="flex" alignItems="center" sx={{paddingRight:'30px'}}>
                {drawCheckbox(selection)}
                {selection.countSelected(totalItems)}
            </Box>
        </Stack>
    )
}

function drawCheckbox(selection: SelectionModel) {
    let isIndeterminate = selection.invertedSelection.size > 0;
    let isCheckedAll = selection.isSelectAll;

    return <Checkbox sx={{paddingLeft:0, paddingRight: '15px'}}
                     onChange={(_ignored, checked) => toggleSelectAllAction()}
                     indeterminate={isIndeterminate}
                     checked={isCheckedAll}/>;
}

function handleSaveFilter(filter: SegmentListFilter,
                          source: string,
                          target: string,
                          sourceMatchCase: boolean,
                          targetMatchCase: boolean,
                          setSource: (s: string) => void,
                          setTarget: (s: string) => void,
                          setSourceMatchCase: (v: boolean) => void,
                          setTargetMatchCase: (v: boolean) => void,
                          navigate: NavigateFunction) {
    let updatedFilter = filter.set("sourceMatchCase", sourceMatchCase).set("targetMatchCase", targetMatchCase);
    if (source)
        updatedFilter = updatedFilter.set("source", source);

    if (target)
        updatedFilter = updatedFilter.set("target", target);

    const currentSegment = segmentEditorStore.getState().segment;
    if (!currentSegment)
        return;
    navigate(getSegmentPath(updatedFilter, currentSegment.order), {replace: true});

    setSource("");
    setTarget("");
    setSourceMatchCase(false);
    setTargetMatchCase(false);
}

function handelOnCompleteEdit(event: React.KeyboardEvent<HTMLDivElement>,
                              filter: SegmentListFilter,
                              source: string,
                              target: string,
                              sourceMatchCase: boolean,
                              targetMatchCase: boolean,
                              setSource: (s: string) => void,
                              setTarget: (s: string) => void,
                              setSourceMatchCase: (v: boolean) => void,
                              setTargetMatchCase: (v: boolean) => void,
                              navigate: NavigateFunction) {
    if (event.key === "Enter") {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement) {
            activeElement.blur();
            handleSaveFilter(
                filter,
                source,
                target,
                sourceMatchCase,
                targetMatchCase,
                setSource,
                setTarget,
                setSourceMatchCase,
                setTargetMatchCase,
                navigate);
        }
    }
}

function handleWorkflowFilterChanged(event: SelectChangeEvent<number[]>,
                                     filter: SegmentListFilter,
                                     navigate: NavigateFunction) {
    const {
        target: {value}
    } = event;
    const workflow = typeof value === 'string' ? value.split(',') : value;
    const updatedFilter = filter.set("workflowIds", Immutable.List(workflow.map(id => Number(id))));
    updatePage(navigate, updatedFilter);
}

async function updatePage(navigate: NavigateFunction, updatedFilter: SegmentListFilter) {
    const currentSegment = segmentEditorStore.getState().segment;
    const url = getSegmentPath(updatedFilter, updatedFilter.isEmpty && currentSegment ? currentSegment.order : null);
    navigate(url, {replace: true});
}