import {Segment} from "../../model/Segment";
import {List} from "immutable";
import Translation from "../../model/Translation";
import {IWorkflowStepResponse, toWorkflowStepModel} from "./Workflow";
import SegmentLock from "../../model/SegmentLock";
import ErrorModel from "../../model/ErrorModel";

export function toSegmentModel(response: ISegmentResponse) {
    return new Segment({
        id: response.id,
        catFileId: response.catFileId,
        source: response.source,
        previousSource: response.previousSource,
        nextSource: response.nextSource,
        sourceId: response.sourceId,
        order: response.order,
        translation: toTranslationModel(response.translation),
        commentsCount: response.commentsCount,
        note: response.note
    });
}

export function toTranslationModel(response: ITranslationResponse) {
    let lock: SegmentLock | null = null;
    if (response.lock != null) {
        lock = new SegmentLock({
            userId: response.lock.userId,
            username: response.lock.username,
            created: new Date(Date.parse(response.lock.created))
        });
    }

    let translation = new Translation({
        target: response.target,
        savedTarget: response.target,
        languageCode: response.languageCode,
        workflowStep: toWorkflowStepModel(response.workflowStep),
        markupErrors: List(response.markupErrors),
        markupErrorModels: List(response.markupErrorModels),
        lock: lock
    });

    if (response.nextWorkflowStep)
        translation = translation.set("nextWorkflowStep", toWorkflowStepModel(response.nextWorkflowStep));

    if (response.previousWorkflowStep)
        translation = translation.set("previousWorkflowStep", toWorkflowStepModel(response.previousWorkflowStep));

    return translation;
}

export interface ISegmentResponse {
    id: number,
    catFileId: number,
    source: string,
    previousSource: string | null,
    nextSource: string | null,
    sourceId: string,
    order: number,
    translation: ITranslationResponse,
    commentsCount: number,
    note: string
}

export interface ITranslationResponse {
    target: string,
    languageCode: string,
    workflowStep: IWorkflowStepResponse,
    previousWorkflowStep: IWorkflowStepResponse | null,
    nextWorkflowStep: IWorkflowStepResponse | null,
    markupErrors: String[],
    markupErrorModels: ErrorModel[],
    lock: ILockResponse | null
}

export interface ILockResponse {
    userId: string,
    username: string,
    created: string
}

