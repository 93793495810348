import Project from "../model/Project";
import {List} from "immutable";
import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError, toParameters} from "../globals/ApiGlobals";
import {
    IProjectBuildResponse,
    IProjectListItemResponse,
    IProjectResponse,
    toProjectBuildModel,
    toProjectListItemModel,
    toProjectModel
} from "./model/ProjectApiModel";
import {WorkflowStepModel} from "../model/WorkflowStepModel";
import {Page} from "../model/Page";
import {Pageable} from "../model/Pageable";
import {Build} from "../model/Build";
import ProjectListItem from "../model/ProjectListItem";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import ProjectListFilter from "../flux/project/list/ProjectListFilter";
import {IUserResponse, toUserModel} from "./model/UserApiModel";
import {User} from "../model/User";
import {SelectionModel} from "../model/SelectionModel";
import TranslationMemory from "../model/TranslationMemory";

export function fetchProject(id: number): Promise<Project> {
    return axios
        .get(endpoint + "/" + id)
        .then((response: AxiosResponse<IProjectResponse>) => toProjectModel(response.data))
        .catch(error => {
            dispatchError(error);
            return new Project();
        });
}

export function fetchProjects(filter: ProjectListFilter, page?: Pageable) {
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<ProjectListFilter>(filter, page)
    );

    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IProjectListItemResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toProjectListItemModel(value));
            return Page
                .fromBackendPage<ProjectListItem>(response.data.page)
                .setList(List(list));
        })
        .catch(dispatchFetchPageError<ProjectListItem>);
}

export function createProject(project: Project,
                              workflowSteps: WorkflowStepModel | null) {
    return axios
        .post(endpoint, {
            id: project.id,
            name: project.name,
            source: project.source.id,
            targets: project.targets.map((language) => language.id),
            translationMemoryIds: project.translationMemories,
            glossaryIds: project.glossaries,
            workflow: workflowSteps
        })
        .then((response: AxiosResponse<IProjectResponse>) => toProjectModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Project()
        })
}

export function deleteProject(id: number) {
    return axios
        .delete(endpoint + "/" + id)
        .catch(dispatchError)
}

export function buildCatFiles(project: Project, selection: SelectionModel) {
    return axios
        .post(endpoint + "/" + project.id + "/build", selection)
        .then((response: AxiosResponse<IProjectBuildResponse>) => toProjectBuildModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Build();
        });
}

export function buildCatFilesTranslations(project: Project, selection: SelectionModel) {
    return axios
        .post(endpoint + "/" + project.id + "/buildTranslations", selection)
        .then((response: AxiosResponse<IProjectBuildResponse>) => toProjectBuildModel(response.data))
        .catch((error) => {
            dispatchError(error);
            return new Build();
        })
}

export function saveTranslationMemories(id: number,
                                        translationMemories: List<TranslationMemory>,
                                        writableTmId: string | null) {
    return axios
        .post(endpoint + "/" + id + "/translation-memories", {
            translationMemoryIds: translationMemories.map(tm => tm.id),
            writableTmId: writableTmId
        })
        .then((value: AxiosResponse<IProjectResponse>) => toProjectModel(value.data))
        .catch((error) => {
            dispatchError(error);
            return new Project();
        });
}

export function saveGlossaries(id: number, glossaryIds: string[]) {
    return axios
        .post(endpoint + "/" + id + "/glossaries", {
            glossaryIds: glossaryIds
        })
        .then((value: AxiosResponse<IProjectResponse>) => toProjectModel(value.data))
        .catch((error) => {
            dispatchError(error);
            return new Project();
        });
}

export function savePlaceholdersSet(id: number,
                                    placeholdersSetId: number | null,
                                    refuseSaveSegmentsWithPlaceholderErrors: boolean) {
    return axios
        .post(endpoint + "/" + id + "/placeholders-set", {
            placeholdersSetId: placeholdersSetId,
            refuseSaveSegmentsWithPlaceholderErrors: refuseSaveSegmentsWithPlaceholderErrors
        })
        .then((value: AxiosResponse<IProjectResponse>) => toProjectModel(value.data))
        .catch((error) => {
            dispatchError(error);
            return new Project();
        });
}

export function fetchProjectUsers(projectId: number) {
    const url = buildUrl(endpoint + "/:projectId/users", {projectId: projectId});
    return axios
        .get(url)
        .then((response: AxiosResponse<IUserResponse[]>) => {
            const list = response.data.map(value => toUserModel(value));
            return new Page<User>().setList(List(list)).setSize(list.length);
        })
        .catch(dispatchFetchPageError<User>);
}

const endpoint = "/api/projects/projects";
