import React, {useEffect, useRef, useState} from "react";
import {Segment} from "../../../../model/Segment";
import SegmentView from "./SegmentView";
import {getSegmentPath} from "../../../../routes/EditorRoute";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {
    isSegmentPositionLoadedAction,
    loadMoreSegmentByPositionAction, refreshTranslationByEditorAction,
    resetSegmentListEditor,
    setLastClickedSegmentAction,
    toggleSegmentSelectionAction,
    toggleWithControlsAction
} from "../../../../flux/segment/list/SegmentListActions";
import {ListChildComponentProps, ListOnItemsRenderedProps, VariableSizeList} from "react-window";
import AutoSizer, {Size} from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import {FontSizeCalc} from "../../../../globals/FontSizeCalc";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {setSegmentByPositionAction} from "../../../../flux/segment/editor/SegmentEditorActions";
import {ReactSetter} from "../../../../globals/Types";
import Immutable from "immutable";
import {SelectionModel} from "../../../../model/SelectionModel";

export default function SegmentList() {
    const listRef = useRef<VariableSizeList | null>(null);
    const [totalItems, setTotalItems] = useState(0);
    const [preloaded, setPreloaded] = useState(Immutable.Map<number, Segment>())
    const [selection, setSelection] = useState(new SelectionModel())
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const list = listRef.current;
        if (!list)
            return;

        list.scrollToItem(position, 'center');
    }, [position]);

    useEffect(() => {
        const list = listRef.current;
        if (!list)
            return;

        listRef.current!.resetAfterIndex(0, true);
        list.scrollToItem(position, 'center');
    }, [preloaded, selection]);

    useEffect(() => {
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setTotalItems(state.totalsItems);
            setPreloaded(state.items);
            setSelection(state.selection);
            setPosition(state.position);
        });

        return () => {
            segmentListListener.remove();
            resetSegmentListEditor();
        }
    }, []);

    return (
        <AutoSizer>
            {(size: Size) => drawLazyLoadedSegments(size.height, size.width, totalItems, listRef, setPosition)}
        </AutoSizer>
    );
}

function drawLazyLoadedSegments(height: number,
                                width: number,
                                totalItems: number,
                                listRef: React.MutableRefObject<VariableSizeList | null>,
                                setPosition: ReactSetter<number>
) {
    return (
        <InfiniteLoader
            isItemLoaded={position => isSegmentPositionLoadedAction(position)}
            itemCount={totalItems}
            loadMoreItems={loadMoreSegmentByPositionAction}>
            {({onItemsRendered}) => {
                return drawSegmentsList(
                    height,
                    width,
                    totalItems,
                    onItemsRendered,
                    listRef,
                    setPosition);
            }}
        </InfiniteLoader>)
}

function drawSegmentsList(
    height: number,
    width: number,
    itemCount: number,
    onItemsRendered: (props: ListOnItemsRenderedProps) => any,
    listRef: React.MutableRefObject<VariableSizeList | null>,
    setPosition: ReactSetter<number>
) {
    return (<VariableSizeList
        height={height}
        width={width}
        itemCount={itemCount}
        onScroll={() => {
            refreshTranslationByEditorAction();
        }}
        itemSize={(i: number) => {
            let segment = segmentListStore.getState().items.get(i);
            if (!segment)
                segment = Segment.Empty;

            // TODO: remove hardcode
            const source = FontSizeCalc.Instance.calcHeight(segment.source, (width - 300) / 2 - 50, 'Roboto', '14px');
            const sourceId = FontSizeCalc.Instance.calcHeight(segment.sourceId, (width - 300) / 2 - 50, 'Roboto', '14px');

            return Math.max(76, source + sourceId)
        }}
        onItemsRendered={onItemsRendered}
        ref={listRef}>
        {({
              index,
              style
          }: ListChildComponentProps<Segment>) => drawSegment(index, style, setPosition)}
    </VariableSizeList>)
}

function drawSegment(position: number,
                     style: React.CSSProperties,
                     setPosition: ReactSetter<number>) {
    let segment = segmentListStore.getState().items.get(position);
    if (!segment)
        segment = Segment.Empty;

    const selection = segmentListStore.getState().selection;

    return (
        <SegmentView
            onClick={(event) =>
                handleSegmentClicked(event, segment, position, setPosition)}
            onShiftSegment={shift => pointToSegment(position + shift, setPosition)}
            segment={segment}
            position={position}
            key={`segment-${segment.id}`}
            style={style}
            isSelected={selection.isSelected(segment.id)}/>
    );
}

function handleSegmentClicked(event: React.MouseEvent,
                              segment: Segment,
                              position: number,
                              setPosition: ReactSetter<number>) {
    selectWithShiftKey(event, position);

    const inCurrent = segmentStore.getState().segment?.id === segment.id;
    if (inCurrent)
        return;

    pointToSegment(position, setPosition);
}

function pointToSegment(position: number,
                        setPosition: ReactSetter<number>) {
    const filter = segmentListStore.getState().filter;

    const totalItems = segmentListStore.getState().totalsItems;
    const isValid = totalItems >= position && position >= 0;

    if (!isValid)
        return;

    // do not use navigate - it drops slate cursor position
    window.history.replaceState(null, '', getSegmentPath(filter, position + 1));
    setPosition(position);
    setSegmentByPositionAction(position);
}

function selectWithShiftKey(event: React.MouseEvent, to: number) {
    if (!event.shiftKey) {
        setLastClickedSegmentAction(null);
        return;
    }

    const state = segmentListStore.getState();
    if (!state.withControls)
        toggleWithControlsAction();

    const from = state.lastClickedPosition;
    if (from == null || from == to) {
        toggleSegmentSelectionAction(to);
        setLastClickedSegmentAction(to);
        return;
    }

    const items = segmentListStore.getState().items;
    let toUpdate;

    if (from > to)
        toUpdate = items.filter((_ignored, position) => from > position && position >= to);
    else if (from < to)
        toUpdate = items.filter((_ignored, position) => to >= position && position > from);

    if (toUpdate)
        toUpdate.forEach((_ignored, position) => {
            toggleSegmentSelectionAction(position)
        });

    setLastClickedSegmentAction(to);
}