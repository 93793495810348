import {createTheme} from "@mui/material";


export const onecIntTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#2196F3"
        }
    },
    typography: {
        fontFamily: 'Source Sans Pro, Arial, sans-serif',
        h1: {
            fontFamily: 'Source Sans Pro, Arial, sans-serif',
        },
        body2: {
            fontFamily: 'Source Sans Pro, Arial, sans-serif',
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'menu'},
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        padding: '6px 12px',
                        height: '30px',
                        border: '1px solid #FFFFFF',
                        borderRadius: '4px',
                        color: '#FFFFFF',
                        backgroundColor: 'transparent',
                        fontSize: '0.75rem',
                        fontWeight: 'normal',
                        '&:hover': {
                            border: '1px solid #FFFFFF',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        }
                    },
                },
                {
                    props: {variant: 'menu2'},
                    style: {
                        display: 'flex',
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 400,
                        alignItems: 'left',
                        padding: '6px 12px',
                        height: '21px',
                        color: '#FFFFFF',
                        backgroundColor: 'transparent',
                        fontSize: '0.75rem',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        }
                    },
                }
            ],
            styleOverrides: {
                root: {
                    fontFamily: 'Source Sans Pro',
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: 600,
                    letterSpacing: '0.4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '4px 10px',
                    borderRadius: '4px',
                    textTransform: 'uppercase',

                },
                contained: {
                    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
                    color: '#FFFFFF',
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    letterSpacing: '0.46px',
                },
            },
        },
        MuiLink: {
            variants: [
                {
                    props: {variant: 'menu'},
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        padding: '6px 12px',
                        height: '30px',
                        border: '1px solid #FFFFFF',
                        borderRadius: '4px',
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 600,
                        color: '#FFFFFF',
                        backgroundColor: 'transparent',
                        fontSize: '0.75rem',
                        '&:hover': {
                            border: '1px solid #FFFFFF',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        }
                    },
                },
            ],
            styleOverrides: {}
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                },
                select: {
                    padding: '8px 12px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                body2: {
                    fontFamily: 'Source Sans Pro',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    color: '#7A869A',
                },
                body1: {
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    flexShrink: 1,
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    borderRadius: '4px',
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#C2E0FF',
                    borderRadius: '5px',
                    padding: '4px 8px',
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    lineHeight: '14px',
                    fontSize: '10px',
                    color: '#091E42'
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.4px',
                    textAlign: 'left',
                    textTransform: 'none',
                },
            },
        },
    },
});
