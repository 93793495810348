import React, {useEffect, useState} from "react";
import {Segment} from "../../../../model/Segment";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Page} from "../../../../model/Page";
import GlossaryPairSearchResult from "../../../../model/GlossaryPairSearchResult";
import {ExistingTranslationsTabName} from "../MiddleRightFrame";
import GlossarySearchResult from "./GlossarySearchResult";
import {
    selectGlossaryPairAction,
    selectTMSuggestAction,
    updateTranslationFromResourceAction
} from "../../../../flux/segment/editor/SegmentEditorActions";
import TranslationMemorySearchResult from "../../../../model/TranslationMemorySearchResult";
import TMSearchResult from "./TMSearchResult";
import {Language} from "../../../../model/Language";
import {Editor} from "slate";

type ExistingTranslationsProperties = {
    currentTab: ExistingTranslationsTabName,
    targetLanguage: Language | null
}

const ScrollableContainer = styled(Box)({
    overflowY: 'auto',
    maxHeight: '80%',
    marginTop: 1
});

export const SearchResultPrefix = "search-result-";

export default function ExistingTranslationsView(props: ExistingTranslationsProperties) {

    const initialSegmentState = segmentStore.getState();

    const [searchResults, setSearchResults]
        = useState(new Page<TranslationMemorySearchResult>());
    const [glossaryPairs, setGlossaryPairs]
        = useState(new Page<GlossaryPairSearchResult>());
    const [segment, setSegment]
        = useState<Segment | null>(initialSegmentState.segment);
    const [selectedRow, setSelectedRow] = useState<number>(-1);
    const [targetEditor, setTargetEditor]
        = useState<Editor | null>(initialSegmentState.targetEditor);

    useEffect(() => {
        const segmentListener = segmentStore.addListener(() => {
            const state = segmentStore.getState();
            setSearchResults(state.searchResults);
            setGlossaryPairs(state.glossaryPairs);
            setSegment(state.segment);
            setTargetEditor(state.targetEditor);
        });

        const searchResult = searchResults.list.first();
        if (searchResult) {
            setSelectedRow(0);
            selectGlossaryPairAction(null);
            selectTMSuggestAction(searchResult);
        }

        return () => {
            segmentListener.remove();
        }
    });

    useEffect(() => {
        setSelectedRow(-1);
    }, [segment]);

    return (
        <ScrollableContainer>
            {drawResults(props, searchResults, glossaryPairs, segment, selectedRow, setSelectedRow, targetEditor)}
        </ScrollableContainer>
    );
}

function handleDoubleClick(segment: Segment | null,
                           searchResult: ISearchResult,
                           targetEditor: Editor | null) {
    if (!segment)
        return;
    updateTranslationFromResourceAction(segment, searchResult, targetEditor);
}

function drawResults(props: ExistingTranslationsProperties,
                     searchResults: Page<TranslationMemorySearchResult>,
                     glossaryPairs: Page<GlossaryPairSearchResult>,
                     segment: Segment | null,
                     selectedRow: number,
                     setSelectedRow: React.Dispatch<React.SetStateAction<number>>,
                     targetEditor: Editor | null) {
    const result: JSX.Element[] = [];
    let index = 0;

    if (props.currentTab === ExistingTranslationsTabName.All
        || props.currentTab === ExistingTranslationsTabName.TM) {
        searchResults.list.forEach((searchResult) => {
            result.push(<TMSearchResult index={index} searchResult={searchResult} onClick={setSelectedRow}
                                        isSelected={selectedRow === index}
                                        onDoubleClick={() => handleDoubleClick(segment, searchResult, targetEditor)}
                                        key={"search-result-" + index}/>);
            index++;
        });
    }

    if (props.currentTab === ExistingTranslationsTabName.All
        || props.currentTab === ExistingTranslationsTabName.TB) {
        glossaryPairs.list.forEach((glossaryPair) => {
            result.push(<GlossarySearchResult index={index} searchResult={glossaryPair} onClick={setSelectedRow}
                                              isSelected={selectedRow === index}
                                              onDoubleClick={() => handleDoubleClick(segment, glossaryPair, targetEditor)}
                                              key={"search-result-" + index}/>);
            index++;
        });
    }

    return result;
}