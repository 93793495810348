import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {ConcordanceSearchResult} from "../../../../model/ConcordanceSearch";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import concordanceSearchStore from "../../../../flux/concordance-search/ConcordanceSearchStore";
import {FoundFragment, NoPaddingContainer} from "../../../../globals/CommonComponents";
import {executeConcordanceSearchAction} from "../../../../flux/concordance-search/ConcordanceSearchActions";
import {updateTranslationFromResourceAction} from "../../../../flux/segment/editor/SegmentEditorActions";
import {Page} from "../../../../model/Page";
import {Segment} from "../../../../model/Segment";
import Project from "../../../../model/Project";
import segmentListStore from "../../../../flux/segment/list/SegmentListStore";
import {Editor} from "slate";

const ScrollableContainer = styled(Box)({
    overflowY: 'auto',
    maxHeight: '70%',
    flexGrow: 1,
});

type ConcordanceSearchResultContainerProps = {
    isHovered: boolean
};

const ConcordanceSearchResultContainer = styled(Grid, {
    shouldForwardProp: propName => propName !== 'isHovered'
})<ConcordanceSearchResultContainerProps>((props): any => ({
    borderBottom: '1px solid #e0e0e0',
    background: props.isHovered ? '#f7f8fc' : '#ffffff',
    cursor: 'pointer'
}));

const ConcordanceSearchResultItem = styled(Grid)({
    padding: '4px 8px',
    fontSize: '14px',
    wordWrap: 'break-word'
});

export default function ConcordanceSearchView() {
    const initialProjectState = projectPageStore.getState();
    const initialSegmentState = segmentStore.getState();
    const initialSegmentListState = segmentListStore.getState();

    const [project, setProject] = useState(initialProjectState.project);
    const [segment, setSegment] = useState(initialSegmentState.segment);
    const [targetLanguage, setTargetLanguage]
        = useState(initialSegmentListState.filter.nonNullLanguage);
    const [searchText, setSearchText] = useState("");
    const [concordanceSearchType, setConcordanceSearchType]
        = useState("Source");
    const [receivedConcordanceSearchResults,
        setReceivedConcordanceSearchResults]
        = useState(new Page<ConcordanceSearchResult>());
    const [hoveredResult, setHoveredResult] = useState(-1);
    const [targetEditor, setTargetEditor]
        = useState<Editor | null>(initialSegmentState.targetEditor);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            setProject(state.project);
        })

        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setTargetLanguage(state.filter.nonNullLanguage);
        });

        const concordanceSearchListener = concordanceSearchStore.addListener(() => {
            const state = concordanceSearchStore.getState();
            setReceivedConcordanceSearchResults(state.concordanceSearchResults);
        })

        const segmentListener = segmentStore.addListener(() => {
            const state = segmentStore.getState();
            setSegment(state.segment);
            setTargetEditor(state.targetEditor);
        })

        return () => {
            projectPageListener.remove();
            segmentListListener.remove();
            concordanceSearchListener.remove();
            segmentListener.remove();
        }
    });

    function drawSearchResultText(text: string) {
        const result: JSX.Element[] = [];
        let currentPos = 0;
        while (true) {
            const foundStartTagPos = text.indexOf("<em>", currentPos);
            if (foundStartTagPos === -1) {
                result.push(<span>{text.substring(currentPos)}</span>);
                break;
            }
            result.push(<span>{text.substring(currentPos, foundStartTagPos)}</span>);
            currentPos = foundStartTagPos + 4;
            const foundEndTagPos = text.indexOf("</em>", currentPos);
            if (foundEndTagPos === -1) {
                result.push(<FoundFragment>{text.substring(currentPos)}</FoundFragment>);
                break;
            }
            result.push(<FoundFragment>{text.substring(currentPos, foundEndTagPos)}</FoundFragment>);
            currentPos = foundEndTagPos + 5;
        }
        return result;
    }

    return (
        <NoPaddingContainer sx={{height: '100vh'}}>
            <Container sx={{maxHeight: '30%'}}>
                <Grid container mt={1} spacing={1}>
                    <Grid item xs={8} key={"concordance-search-field"}>
                        <TextField size={"small"} fullWidth={true} value={searchText}
                                   onChange={(e) => setSearchText(e.target.value)}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter')
                                           handleSearchClicked(project, searchText, concordanceSearchType, targetLanguage)
                                   }
                                   }/>
                    </Grid>
                    <Grid item xs={"auto"} key={"concordance-search-button"}>
                        <Button variant={"contained"} sx={{height: '40px'}}
                                onClick={() =>
                                    handleSearchClicked(project, searchText, concordanceSearchType, targetLanguage)}>
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12} key={"concordance-search-type"}>
                        <FormControl>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <FormLabel>Search in:</FormLabel>
                                <RadioGroup row
                                            onChange={e =>
                                                setConcordanceSearchType(e.target.value)}
                                            value={concordanceSearchType}>
                                    <FormControlLabel control={<Radio/>} label={"source"} value={"Source"}/>
                                    <FormControlLabel control={<Radio/>} label={"target"} value={"Target"}/>
                                </RadioGroup>
                            </Stack>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
            <ScrollableContainer>
                {receivedConcordanceSearchResults.list.map((searchResult, index) => (
                    <ConcordanceSearchResultContainer container isHovered={index === hoveredResult}
                                                      key={"concordance-search-result-" + index}
                                                      onMouseOver={() => setHoveredResult(index)}
                                                      onMouseLeave={() => setHoveredResult(-1)}
                                                      onDoubleClick={() =>
                                                          handleUpdateTranslation(segment, searchResult, targetEditor)}>
                        <ConcordanceSearchResultItem item xs={2}
                                                     key={"concordance-search-result-index-" + index}>
                            {index + 1}
                        </ConcordanceSearchResultItem>
                        <ConcordanceSearchResultItem item xs={5}
                                                     key={"concordance-search-result-source-" + index}>
                            {drawSearchResultText(searchResult.source)}
                        </ConcordanceSearchResultItem>
                        <ConcordanceSearchResultItem item xs={5}
                                                     key={"concordance-search-result-target-" + index}>
                            {drawSearchResultText(searchResult.target)}
                        </ConcordanceSearchResultItem>
                    </ConcordanceSearchResultContainer>
                ))}
            </ScrollableContainer>
        </NoPaddingContainer>
    );
}

function handleUpdateTranslation(segment: Segment | null,
                                 concordanceSearchResult: ConcordanceSearchResult,
                                 targetEditor: Editor | null) {
    if (!segment)
        return;
    updateTranslationFromResourceAction(segment, concordanceSearchResult, targetEditor);
}

function handleSearchClicked(project: Project,
                             searchText: string,
                             concordanceSearchType: string,
                             targetLanguage: string) {
    if (!project)
        return;
    executeConcordanceSearchAction(
        searchText,
        concordanceSearchType,
        project.source.id,
        targetLanguage,
        project.translationMemories);
}