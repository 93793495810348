import React, {useEffect, useState} from "react";
import {hasModel} from "../../../../../globals/Utils";
import {CircularProgress, Menu, MenuItem, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import {CatFile} from "../../../../../model/CatFile";
import {
    catFileBuildActions,
    selectedCatFilesBuildActions
} from "../../../../../flux/common/build/TranslationsBuildActions";

type DownloadFileActionProps = {
    catFile: CatFile
}

export default function DownloadFileAction(props: DownloadFileActionProps) {
    const [anchor, setAnchor]
        = useState<HTMLElement | null>(null);
    const open = Boolean(anchor);

    const buildState = selectedCatFilesBuildActions.state;
    const [buildingFiles, setBuildingFiles]
        = useState(buildState.building);

    useEffect(() => {
        const buildStoreListener = selectedCatFilesBuildActions.addListener(() => {
            const state = selectedCatFilesBuildActions.state;
            setBuildingFiles(state.building);
        });

        return () => buildStoreListener.remove();
    }, []);

    const handleSaveXliffClicked = () => {
        catFileBuildActions.build(props.catFile, true);
        setAnchor(null);
    }

    const handleSaveTranslationClicked = () => {
        catFileBuildActions.build(props.catFile, false);
        setAnchor(null);
    }

    if (hasModel(props.catFile, buildingFiles))
        return <CircularProgress size={20}/>;
    else {
        return <Box>
            <GridActionsCellItem label={'Download'} icon={<Tooltip title={"Download"} arrow><DownloadIcon/></Tooltip>}
                                 onClick={e => setAnchor(e.currentTarget)}/>
            <Menu open={open} onClose={() => setAnchor(null)} anchorEl={anchor}>
                <MenuItem onClick={handleSaveTranslationClicked}>Download translation</MenuItem>
                <MenuItem onClick={handleSaveXliffClicked}>Download xliff</MenuItem>
            </Menu>
        </Box>;
    }
}