import React, {useState} from "react";
import {Container, Tabs} from "@mui/material";
import CommentList from "./comment/CommentList";
import ActivityList from "../../activity/ActivityList";
import {List} from "immutable";
import {AvailableResourceType} from "../../../model/AvailableResources";
import PrivateTab from "../../PrivateTab";
import ExternalCommentList from "./comment/ExternalCommentList";

export default function BottomLeftFrame() {
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <Container maxWidth={false} style={{ height: '100%',maxHeight:'100%' }}>
            <Tabs value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
                <PrivateTab readAllow={true} writeAllow={true} deleteAllow={false}
                            resources={List.of(AvailableResourceType.Comments)} label={"Comments"}/>
                <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                            resources={List.of(AvailableResourceType.SegmentActivity)} label={"Activity"}/>
                <PrivateTab readAllow={true} writeAllow={false} deleteAllow={false}
                            resources={List.of(AvailableResourceType.Segments)} label={"External comments"}/>
            </Tabs>
            {drawTab(currentTab)}
        </Container>
    )
}

function drawTab(currentTab: number) {
    switch (currentTab) {
        case 0:
            return <CommentList/>
        case 1:
            return <ActivityList/>
        case 2:
            return <ExternalCommentList/>
    }
}