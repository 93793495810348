import axios, {AxiosResponse} from "axios";
import {buildUrl, dispatchError, dispatchFetchPageError} from "../globals/ApiGlobals";
import {List} from 'immutable';
import {Report, ReportType} from "../model/Report";
import {Page} from "../model/Page";
import {AssigneeReportFilter, ProjectReportFilter,} from "../model/ReportFilters";
import {Mutex} from "async-mutex";
import {IReportResponse, toReportModel} from "./model/ReportApiModel";
import {Pageable} from "../model/Pageable";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {dateTimeFormat, utcDate} from "../globals/Utils";
import {SelectionModel} from "../model/SelectionModel";

export const ReportListMutex = new Mutex();

export function fetchProjectReport(filter: ProjectReportFilter, page: Pageable) {
    const backendPage = page.asBackendPage();
    let url = buildUrl(endpoint + "/project-report/search",
        {},
        {
            "projectId": filter.projectId.toString(),
            "page": backendPage.number.toString(),
            "size": backendPage.size.toString()
        });

    return ReportListMutex
        .runExclusive(() => fetchReportPromise(url, ReportType.Project))
        .catch(dispatchFetchPageError<Report>);
}

export function generateProjectReport(filter: ProjectReportFilter, selection: SelectionModel) {
    let url = buildUrl(endpoint + "/project-report/generate",
        {},
        );

    return axios
        .post(url, {
            projectId: filter.projectId.toString(),
            selection: selection,
        })
        .catch(dispatchError);
}

export function fetchAssigneeReport(filter: AssigneeReportFilter, page: Pageable) {
    const backendPage = page.asBackendPage();
    let url = buildUrl(endpoint + "/translation-assignee/search",
        {},
        {
            "projectId": filter.projectId.toString(),
            "page": backendPage.number.toString(),
            "size": backendPage.size.toString()
        });

    return ReportListMutex
        .runExclusive(() => fetchReportPromise(url, ReportType.TranslationAssignee))
        .catch(dispatchFetchPageError<Report>);
}

export function generateAssigneeReport(filter: AssigneeReportFilter) {
    let url = buildUrl(endpoint + "/translation-assignee/generate",
        {},
        {
            "projectId": filter.projectId.toString(),
            "from": filter.from ? utcDate(filter.from).toISOString() : null,
            "to": filter.to ? utcDate(filter.to).toISOString() : null
        });

    return axios
        .post(url)
        .catch(dispatchError);
}


export function deleteReport(report: Report) {
    switch (report.variety) {
        case ReportType.Project:
            deleteProjectReport(report.id);
            break;
        case ReportType.TranslationAssignee:
            deleteAssigneeReport(report.id)
            break;
    }
}

export function downloadReport(report: Report) {
    const fileDownload = require("js-file-download");
    let reportName = "Project_Statistics";
    let reportPath = "project-report";
    if (report.variety === ReportType.TranslationAssignee) {
        reportPath = "translation-assignee";
        reportName = "Translator_Statistics"
    }

    axios
        .get(endpoint + "/" + reportPath + "/" + report.id + "/download", {responseType: 'blob'})
        .then((response) => {
            const filename = reportName + "_" + dateTimeFormat(new Date()) + ".xlsx";
            fileDownload(response.data, filename);
        })
        .catch(dispatchError);
}

function deleteProjectReport(id: number) {
    let url = buildUrl(endpoint + "/project-report/:id",
        {id: id});

    axios
        .delete(url)
        .catch(dispatchError);
}

function deleteAssigneeReport(id: number) {
    let url = buildUrl(endpoint + "/translation-assignee/:id",
        {id: id});

    axios
        .delete(url)
        .catch(dispatchError);
}

function fetchReportPromise(url: string, variety: ReportType) {
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<IReportResponse[]>>) => {
            const list = response
                .data
                ._embedded
                .map(value => toReportModel(value, variety));

            return Page
                .fromBackendPage<Report>(response.data.page)
                .setList(List(list));
        });
}

const endpoint = "/api/reports";