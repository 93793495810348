export enum CatEventType {
    CatFileCreatedEvent = "com.onecint.cat.projects.events.catfile.CatFileCreatedEvent",
    CatFileDeletedEvent = "com.onecint.cat.projects.events.catfile.CatFileDeletedEvent",
    CatFileRolledBackEvent = "com.onecint.cat.projects.events.catfile.CatFileRolledBackEvent",
    CatFileUpdatedEvent = "com.onecint.cat.projects.events.catfile.CatFileUpdatedEvent",

    SegmentCreatedEvent = "com.onecint.cat.projects.events.segments.SegmentCreatedEvent",
    SegmentDeletedEvent = "com.onecint.cat.projects.events.segments.SegmentDeletedEvent",
    SegmentUpdatedEvent = "com.onecint.cat.projects.events.segments.SegmentUpdatedEvent",
    SegmentRolledBackEvent = "com.onecint.cat.projects.events.segments.SegmentRolledBackEvent",

    TranslationRolledBackEvent = "com.onecint.cat.projects.events.segments.TranslationRolledBackEvent",
    SegmentTranslationSavedEvent = "com.onecint.cat.projects.events.segments.SegmentTranslationSavedEvent",
    ClearTargetEvent = "com.onecint.cat.projects.events.segments.ClearTargetEvent",
    TranslationAssignee = "com.onecint.cat.projects.events.segments.SegmentTranslationAssigneeEvent",
    Undefined = "EventType.Undefined"
}

export function eventTypePresentation(eventType: CatEventType): string {
    switch (eventType) {
        case CatEventType.CatFileCreatedEvent:
            return "created cat file";
        case CatEventType.CatFileDeletedEvent:
            return "deleted cat file"
        case CatEventType.CatFileRolledBackEvent:
            return "cat file rolled back"
        case CatEventType.CatFileUpdatedEvent:
            return "cat file updated"
        case CatEventType.SegmentCreatedEvent:
            return "created segment"
        case CatEventType.SegmentDeletedEvent:
            return "deleted segment"
        case CatEventType.SegmentUpdatedEvent:
            return "updated segment"
        case CatEventType.SegmentRolledBackEvent:
            return "segment rolled back"
        case CatEventType.TranslationRolledBackEvent:
            return "translation rolled back";
        case CatEventType.TranslationAssignee:
            return "Confirmed translation"
        case CatEventType.SegmentTranslationSavedEvent:
            return "added translations";
        case CatEventType.ClearTargetEvent:
            return "clear target";
        case CatEventType.Undefined:
        default:
            return "undefined"
    }
}