import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Button, Chip, Container, Fab, Grid, Stack, Tab} from "@mui/material";
import Drawer, {DrawerProps} from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export const DataGridContainer = styled(Box)({
    width: '100%',
    flex: 1,
    overflow: 'auto',
    '& .activity-list--target': {
        whiteSpace: 'pre-wrap'
    },
    '& .translation-memory--state': {
        color: 'red'
    }
});

export const SimpleButton = styled(Button)({
    textTransform: 'none'
});

export const CustomChip = styled(Chip)({
    height: 18,
    fontSize: 12
});

export const OverflowContainer = styled(Container)({
    overflowY: 'auto',
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

export const NoPaddingContainer = styled(Container)({
    padding: 0,
    '@media (min-width:600px)': {
        padding: 0
    }
});

interface CustomDrawerProps extends DrawerProps {
    width: number;
}

export const CustomDrawer = styled(Drawer)<CustomDrawerProps>(
    (props: CustomDrawerProps) => ({
        width: props.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: props.width,
            transform: `translateX(${props.width}px)`,
            ...(props.open && {
                transform: 'translateX(0px)'
            })
        }
    })
);

export const CommonLink = styled(Link)({
    textDecoration: 'none',
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
});

export const RightBottomFab = styled(Fab)({
    position: 'absolute',
    bottom: 30,
    right: 30,
});

export const BreakWordGrid = styled(Grid)({
    wordWrap: 'break-word'
});

type SearchResultGridProps = {
    isSelected: boolean
}

export const SearchResultGrid = styled(Grid, {
    shouldForwardProp: propName => propName !== 'isSelected'
})<SearchResultGridProps>((props) => ({
    backgroundColor: props.isSelected ? '#f3f0fb' : '#FAFBFC',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f6f6f6'
    }
}));

export const SlateParagraph = styled('p')({
    marginTop: 0,
    marginBottom: '2px'
});

export const FoundFragment = styled('span')({
    color: 'red'
});

export const Placeholder = styled('span')({
    cursor: 'pointer',
    margin: '0px 1px',
    padding: '1px 4px',
    marginBottom: '0px',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 207, 108, 1)',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgba(173, 211, 146, 1)'
    }
});

export const DrawerPart = styled(Stack)({
    padding: '8px 24px'
});

export const SimpleTab = styled(Tab)({
    textTransform: 'none',
    padding:'16px'
});

export const Bold = styled(Typography)({
    fontWeight: 'bold'
});

export const SmallText = styled(Typography)({
    fontSize: 12
});

type CollapseExpandButtonProps = {
    isBottomHidden: boolean
}

export const CollapseExpandButton = styled(Button, {
    shouldForwardProp: propName => propName !== 'isBottomHidden'
})<CollapseExpandButtonProps>((props) => ({
    width: '100%',
    height: '22px',
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4F5F7',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    textAlign: 'center',
    fontSize: '14px',
    transition: 'background-color 0.3s ease',
    marginBottom: props.isBottomHidden ? '40px' : 0,
    '&:hover': {
        backgroundColor: '#E0E0E0',
    },
    '& .button-text': {
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.14px',
        textAlign: 'left',
        marginRight: '8px',
        color: '#091E42'
    },
    '& .arrow-icon': {
        width: '24px',
        height: '24px',
    },
}));
