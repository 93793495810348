import {List, Record} from "immutable";
import {IFilter} from "../../../model/Filter";
import {CatFile} from "../../../model/CatFile";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {addSearchParam} from "../../../utils/ListViewUtils";
import dayjs from "dayjs";

export interface ISegmentListFilter {
    projectId: number,
    language: string | null,
    catFile: CatFile | null,
    workflowIds: List<number> | null,
    source: string | null,
    target: string | null,
    sourceId: string | null,
    sourceMatchCase: boolean,
    targetMatchCase: boolean,
    withComments: boolean,
    withoutComments:boolean,
    withLocked: boolean,
    withUnlocked: boolean,
    authors: List<string> | null,
    from: Date | null,
    to: Date | null,
    orderBy: string | null
}

const SegmentListFilterRecord = Record<ISegmentListFilter>({
    projectId: 0,
    language: null,
    catFile: null,
    workflowIds: null,
    source: null,
    target: null,
    sourceId: null,
    sourceMatchCase: false,
    targetMatchCase: false,
    withComments: false,
    withoutComments: false,
    withLocked: false,
    withUnlocked: false,
    authors: null,
    from: null,
    to: null,
    orderBy: null
})

export default class SegmentListFilter extends SegmentListFilterRecord implements IFilter<SegmentListFilter> {
    updateWithGridFilterModel(): SegmentListFilter {
        throw new Error("Unsupported")
    }

    updateWithGridSortingModel(): SegmentListFilter {
        throw new Error("Unsupported")
    }

    toSearchParams(): string {
        let result: string[] = [];
        addSearchParam(result, "workflowIds", this.workflowIds ? this.workflowIds.toArray() : null);
        addSearchParam(result, "source", this.source? encodeURIComponent(this.source): null);
        addSearchParam(result, "target", this.target? encodeURIComponent(this.target): null);
        addSearchParam(result, "sourceId", this.sourceId);
        addSearchParam(result, "sourceMatchCase", this.sourceMatchCase);
        addSearchParam(result, "targetMatchCase", this.targetMatchCase);
        addSearchParam(result, "withComments", this.withComments);
        addSearchParam(result, "withoutComments", this.withoutComments);
        addSearchParam(result, "withLocked", this.withLocked);
        addSearchParam(result, "withUnlocked", this.withUnlocked);
        addSearchParam(result, "authors", this.authors ? this.authors.toArray() : null);
        addSearchParam(result, "from", this.from ? this.from.toISOString() : null);
        addSearchParam(result, "to", this.to ? this.to.toISOString() : null);

        return result.join("&");
    }

    equals(other: SegmentListFilter): boolean {
        if (other === null)
            return false;
        return this.projectId === other.projectId
            && this.language === other.language
            && this.catFile?.id === other.catFile?.id
            && (this.workflowIds === null && other.workflowIds === null
                || this.workflowIds !== null && this.workflowIds.equals(other.workflowIds))
            && this.source === other.source
            && this.target === other.target
            && this.sourceId === other.sourceId
            && this.sourceMatchCase === other.sourceMatchCase
            && this.targetMatchCase === other.targetMatchCase
            && this.withComments === other.withComments
            && this.withoutComments === other.withoutComments
            && this.withUnlocked === other.withUnlocked
            && this.withLocked === other.withLocked
            && (this.authors === null && other.authors === null
                || this.authors !== null && this.authors.equals(other.authors))
            && this.from === other.from
            && this.to === other.to;
    }

    static fromSearchParams(catFile: CatFile, language: string, searchParams: URLSearchParams) {
        let source = searchParams.get("source");
        if (source)
            source = decodeURIComponent(source);

        let target = searchParams.get("target");
        if (target)
            target = decodeURIComponent(target);

        let sourceId = searchParams.get("sourceId");
        if (sourceId)
            sourceId = decodeURIComponent(sourceId);

        let from = searchParams.get("from");
        let to = searchParams.get("to");

        return new SegmentListFilter({
            projectId: catFile.projectId,
            language: language,
            catFile: catFile,
            workflowIds: List(searchParams.getAll("workflowIds").map(id => Number(id))),
            source: source,
            target: target,
            sourceId: sourceId,
            sourceMatchCase: searchParams.get("sourceMatchCase") === "true",
            targetMatchCase: searchParams.get("targetMatchCase") === "true",
            withoutComments: searchParams.get("withoutComments") === "true",
            withComments: searchParams.get("withComments") === "true",
            withUnlocked: searchParams.get("withUnlocked") === "true",
            withLocked: searchParams.get("withLocked") === "true",
            authors: List(searchParams.getAll("authors")),
            from: from ? dayjs(from).toDate() : null,
            to: to ? dayjs(to).toDate() : null
        });
    }

    toGridFilterModel(): GridFilterModel {
        return {items: []};
    }

    toGridSortModel(): GridSortModel {
        return [];
    }

    toObject(): any {
        return {
            projectId: this.projectId,
            language: this.language,
            catFileId: this.catFile?.id,
            workflowIds: this.workflowIds,
            source: this.source,
            target: this.target,
            sourceId: this.sourceId,
            sourceMatchCase: this.sourceMatchCase,
            targetMatchCase: this.targetMatchCase,
            withComments: this.withComments,
            withoutComments: this.withoutComments,
            withLocked: this.withLocked,
            withUnlocked: this.withUnlocked,
            authors: this.authors,
            from: this.from,
            to: this.to,
            orderBy: this.orderBy
        }
    }

    get nonNullLanguage() {
        if (this.language === null)
            return "";

        return this.language;
    }

    get nonNullCatFile() {
        if (this.catFile === null)
            return new CatFile();

        return this.catFile;
    }

    get untranslated() {
        return this.target === "";
    }

    get isEmpty() {
        return (this.workflowIds === null || this.workflowIds.isEmpty())
            && this.source === null
            && this.target === null
            && this.sourceId === null
            && !this.sourceMatchCase
            && !this.targetMatchCase
            && !this.withComments
            && !this.withoutComments
            && !this.withLocked
            && !this.withUnlocked
            && (this.authors === null || this.authors.isEmpty())
            && this.from === null
            && this.to == null;
    }

    setIsUntranslated(isUntranslated: boolean) {
        return isUntranslated ? this.set("target", "") : this.set("target", null);
    }

    setWithComments(withComments: boolean) {
        return withComments ? this.set("withComments", true).set("withoutComments", false) : this.set("withComments", false);
    }

    setWithoutComments(withoutComments: boolean) {
        return withoutComments ? this.set("withoutComments", true) : this.set("withoutComments", false);
    }
    setWithLocked(withLocked: boolean) {
        return withLocked ? this.set("withLocked", true).set("withUnlocked", false) : this.set("withLocked", false);
    }

    setWithUnlocked(withUnlocked: boolean) {
        return withUnlocked ? this.set("withUnlocked", true).set("withLocked", false) : this.set("withUnlocked", false);
    }
}