import TranslationMemory from "../../../model/TranslationMemory";

export enum TranslationMemoryEditorAction {
    SetTm = "TranslationMemoryEditorAction.SetTm",
    SetExistedTm = "TranslationMemoryEditorAction.SetExistedTm"
}

interface ISetTmPayload {
    action: TranslationMemoryEditorAction.SetTm,
    tm: TranslationMemory
}

interface ISetExistedTmPayload {
    action: TranslationMemoryEditorAction.SetExistedTm,
    existed: TranslationMemory | null
}

export type TranslationMemoryEditorPayload = ISetTmPayload | ISetExistedTmPayload;

export function setEditedTranslationMemory(tm: TranslationMemory): TranslationMemoryEditorPayload {
    return {
        action: TranslationMemoryEditorAction.SetTm,
        tm: tm
    }
}

export function setExistedTranslationMemory(existed: TranslationMemory | null): TranslationMemoryEditorPayload {
    return {
        action: TranslationMemoryEditorAction.SetExistedTm,
        existed: existed
    }
}
