import {fetchProject, saveGlossaries, savePlaceholdersSet, saveTranslationMemories} from "../../../api/ProjectApi";
import {dispatcher} from "../../Dispatcher";
import {setFilesState, setProjectPayload} from "./ProjectPagePayload";
import {updateTranslations, uploadFile} from "../../../api/FileApi";
import {CatFile, FileState} from "../../../model/CatFile";
import {List, Set} from "immutable";
import {PretranslateMode} from "../../../model/PretranslateMode";
import {pretranslate} from "../../../api/PretranslateApi";
import Project from "../../../model/Project";
import {setSelectedPayload} from "../../common/list/CommonListPayload";
import {catFileSelectActions} from "../../cat-files/select/CatFileSelectActions";
import {setWaitingForAlertAction} from "../../alert/AlertActions";
import TranslationMemory from "../../../model/TranslationMemory";

export function initProjectAction() {
    dispatcher.dispatch(setProjectPayload(new Project()));
}

export async function uploadFilesAction(files: List<File>, id: number, multiLanguage: boolean) {
    files.map(file => uploadFile(file, id, file.name, multiLanguage));
}

export function uploadFileAction(file: File,
                                 catFile: CatFile,
                                 projectId: number,
                                 fileName: string,
                                 multiLanguage: boolean) {
    dispatcher.dispatch(setFilesState(List.of(catFile.id), FileState.SegmentsReading));
    uploadFile(file, projectId, fileName, multiLanguage)
}

export function updateTranslationsAction(file: File, catFile: CatFile) {
    dispatcher.dispatch(setFilesState(List.of(catFile.id), FileState.Translating))
    updateTranslations(file, catFile);
}

export async function savePlaceholdersSetAction(id: number,
                                                placeholdersSetId: number | null,
                                                refuseSaveSegmentsWithPlaceholderErrors: boolean) {
    const project = await savePlaceholdersSet(id, placeholdersSetId, refuseSaveSegmentsWithPlaceholderErrors);
    dispatcher.dispatch(setProjectPayload(project));
}

export async function saveGlossariesAction(id: number, glossaryIds: List<string>) {
    const project = await saveGlossaries(id, glossaryIds.toArray());
    dispatcher.dispatch(setProjectPayload(project));
}

export async function saveTranslationMemoriesAction(
    id: number,
    translationMemories: List<TranslationMemory>,
    writableTmId: string | null) {
    const project = await saveTranslationMemories(id, translationMemories, writableTmId);
    dispatcher.dispatch(setProjectPayload(project));
    setWaitingForAlertAction(writableTmId !== null);
}

export async function fetchProjectAction(id: number) {
    const project = await fetchProject(id);
    dispatcher.dispatch(setProjectPayload(project));
    return project;
}

export async function pretranslateAction(projectId: number,
                                         pretranslateMode: PretranslateMode,
                                         translationMemory: TranslationMemory,
                                         workflowStepId: number | null,
                                         minimalWordsAmount: number | null,
                                         catFileIds: List<number>) {
    dispatcher.dispatch(setFilesState(List<number>(), FileState.Pretranslating));
    pretranslate(projectId, pretranslateMode, translationMemory, workflowStepId, minimalWordsAmount, catFileIds);
    dispatcher.dispatch(setSelectedPayload(catFileSelectActions.storeId, Set()));
}
