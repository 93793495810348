import {List, Record} from "immutable";
import WorkflowStep from "./WorkflowStep";
import {History} from "slate-history";
import {Editor} from "slate";
import SegmentLock from "./SegmentLock";
import ErrorModel from "./ErrorModel";

// TODO remove cursorPosition,target, savedTarget etc. State of translation should be stored in editor
interface ITranslation {
    target: string,
    savedTarget: string,
    cursorPosition: number | null,
    languageCode: string,
    workflowStep: WorkflowStep,
    previousWorkflowStep: WorkflowStep,
    nextWorkflowStep: WorkflowStep,
    markupErrors: List<String>,
    markupErrorModels: List<ErrorModel>,
    tryToApprove: boolean,
    history: History | null,
    editor: Editor | null,
    lock: SegmentLock | null
}

const TranslationRecord = Record<ITranslation>({
    target: '',
    savedTarget: '',
    cursorPosition: null,
    languageCode: '',
    workflowStep: new WorkflowStep(),
    previousWorkflowStep: new WorkflowStep(),
    nextWorkflowStep: new WorkflowStep(),
    markupErrors: List(),
    markupErrorModels: List(),
    tryToApprove: false,
    history: null,
    editor: null,
    lock: null
});

export class Translation extends TranslationRecord {
    public static UnavailableTranslation = new Translation({});

    get isLocked() {
        return this.get("lock") != null;
    }

    public withCursorPosition(cursorPosition: number | null) {
        return this.set("cursorPosition", cursorPosition);
    }

    public withHistory(history: History | null) {
        return this.set("history", history);
    }

    public withEditor(editor: Editor) {
        return this.set("editor", editor);
    }

    public withTryToApprove(tryToApprove: boolean) {
        return this.set("tryToApprove", tryToApprove);
    }

    public withMarkupErrors(markupErrors: List<String>) {
        return this.set("markupErrors", markupErrors);
    }
    public withMarkupErrorModels(markupErrorModels: List<ErrorModel>) {
        return this.set("markupErrorModels", markupErrorModels);
    }
}

export default Translation;
