import axios, {AxiosResponse} from "axios";
import {downloadFile} from "../globals/ApiGlobals";
import {Build} from "../model/Build";
import {IProjectBuildResponse, toProjectBuildModel} from "./model/ProjectApiModel";

// TODO: put on store
export function fetchCatFilesBuild(build: Build) {
    return axios
        .get(endpoint + "/" + build.id)
        .then((response: AxiosResponse<IProjectBuildResponse>) => toProjectBuildModel(response.data));
}

export function downloadCatFiles(build: Build) {
    downloadFile(endpoint, build);
}

const endpoint = "/api/projects/projectBuilds";