import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton, Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {getGridStringOperators, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import UploadedTranslationMemoryEditor from "./UploadedTranslationMemoryEditor";
import PageBreadcrumbs from "../PageBreadcrumbs";
import ListView from "../common/ListView";
import {translationMemoryListActions} from "../../flux/translation-memory/list/TranslationMemoryListActions";
import {setEditedTranslationMemoryAction} from "../../flux/translation-memory/editor/TranslationMemoryEditorActions";
import TranslationMemory, {
    isPending,
    TranslationMemoryGroup,
    TranslationMemoryState
} from "../../model/TranslationMemory";
import UploadIcon from "@mui/icons-material/Upload";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {GridRowStringOperatorsDefault} from "../../globals/Constants";
import {getTranslationMemoriesPath} from "../../routes/TranslationMemoriesRoute";
import TranslationMemoryFilter from "../../flux/translation-memory/TranslationMemoryFilter";
import {
    translationMemoryBuildActions,
    translationMemoryGroupBuildActions
} from "../../flux/translation-memory/build/TranslationMemoryBuildActions";
import {List} from "immutable";
import {hasModel} from "../../globals/Utils";
import {EmptyTranslationMemoryEditor} from "./EmptyTranslationMemoryEditor";
import {useInterval} from "../../globals/CustomHooks";

export default function TranslationMemoryList() {
    const buildState = translationMemoryBuildActions.state;
    const groupBuildState = translationMemoryGroupBuildActions.state;
    const listState = translationMemoryListActions.state;

    const [openUploadEditor, setOpenUploadEditor] = useState(false);
    const [openEmptyEditor, setOpenEmptyEditor] = useState(false);
    const [groupBuild, setGroupBuild] = useState(!groupBuildState.building.isEmpty());
    const [buildingTranslationMemories, setBuildingTranslationMemories] = useState(buildState.building);
    const [selected, setSelected] = useState(listState.selected)

    useEffect(() => {
        const buildListener = translationMemoryBuildActions.addListener(() => {
            const state = translationMemoryBuildActions.state;
            setBuildingTranslationMemories(state.building);
        });

        const groupBuildListener = translationMemoryGroupBuildActions.addListener(() => {
            const state = translationMemoryGroupBuildActions.state;
            setGroupBuild(!state.building.isEmpty());
        });

        const listListener = translationMemoryListActions.addListener(() => {
            const state = translationMemoryListActions.state;
            setSelected(state.selected);
        });

        return () => {
            buildListener.remove()
            groupBuildListener.remove();
            listListener.remove();
        }
    });

    useInterval(() => {
        translationMemoryListActions.refresh(false);
    }, 2000);

    return (
        <Stack sx={{height: '100%'}}>
            <UploadedTranslationMemoryEditor open={openUploadEditor} onClose={() => setOpenUploadEditor(false)}/>
            <EmptyTranslationMemoryEditor open={openEmptyEditor} onClose={() => setOpenEmptyEditor(false)}/>
            <Stack sx={{height: '100%'}}>
                <PageBreadcrumbs/>
                <Stack direction={"row"}>
                    <IconButton onClick={() => {
                        setEditedTranslationMemoryAction(new TranslationMemory());
                        setOpenEmptyEditor(true);
                    }}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditedTranslationMemoryAction(new TranslationMemory());
                        setOpenUploadEditor(true);
                    }}>
                        <UploadIcon/>
                    </IconButton>
                    <IconButton onClick={() =>
                        translationMemoryGroupBuildActions.build(new TranslationMemoryGroup(selected))}
                                disabled={groupBuild || selected.isEmpty()}>
                        {groupBuild && <CircularProgress sx={{position: 'absolute'}}/>}
                        <DownloadIcon/>
                    </IconButton>
                    <IconButton onClick={() => translationMemoryListActions.deleteSelected()}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
                <ListView actions={translationMemoryListActions}
                          columns={columns(setOpenUploadEditor, buildingTranslationMemories)}
                          navigateProps={{navigator: getTranslationMemoriesPath}}
                          initialFilter={new TranslationMemoryFilter()}/>
            </Stack>
        </Stack>
    );
}

function columns(setOpenEditor: React.Dispatch<React.SetStateAction<boolean>>,
                 buildingTranslationMemories: List<TranslationMemory>): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'sourceLanguage',
            sortable: false,
            headerName: 'Source',
            flex: 1,
            filterOperators: getGridStringOperators().filter(operator =>
                GridRowStringOperatorsDefault.includes(operator.value))
        },
        {
            field: 'targetLanguage',
            filterable: false,
            sortable: false,
            headerName: 'Target',
            valueGetter: (params: GridValueGetterParams<TranslationMemory>) => {
                return params.row.targetLanguages.join(",")
            },
            flex: 1
        },
        {
            field: 'state',
            filterable: false,
            sortable: false,
            headerName: 'State',
            valueGetter: (params: GridValueGetterParams<TranslationMemory>) => {
                if (params.row.translationMemoryState === TranslationMemoryState.Error)
                    return 'Error';
                return '';
            },
            cellClassName: 'translation-memory--state'
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            flex: 1,
            getActions: (params: GridRowParams<TranslationMemory>) => [
                generateUploadTranslationMemory(params.row, setOpenEditor),
                generateDownloadTranslationMemory(params.row, buildingTranslationMemories),
                generateRemoveTranslationMemory(params.row)
            ]
        }
    ];
}

function generateUploadTranslationMemory(translationMemory: TranslationMemory,
                                         setOpenEditor: (v: boolean) => void) {
    if (translationMemory.translationMemoryState === TranslationMemoryState.SegmentsReading)
        return <CircularProgress size={20}/>;
    return <GridActionsCellItem label={'Update'} icon={<UploadIcon/>}
                                onClick={() => {
                                    setOpenEditor(true);
                                    setEditedTranslationMemoryAction(translationMemory);
                                }}
                                disabled={isPending(translationMemory)}/>
}

function generateDownloadTranslationMemory(translationMemory: TranslationMemory,
                                           buildingTranslationMemories: List<TranslationMemory>) {
    if (hasModel(translationMemory, buildingTranslationMemories))
        return <CircularProgress size={20}/>;
    return <GridActionsCellItem label={'Download'} icon={<DownloadIcon/>}
                                onClick={() =>
                                    translationMemoryBuildActions.build(translationMemory)}
                                disabled={translationMemory.translationMemoryState
                                    !== TranslationMemoryState.Available}/>
}

function generateRemoveTranslationMemory(translationMemory: TranslationMemory) {
    if (translationMemory.translationMemoryState === TranslationMemoryState.Removing)
        return <CircularProgress size={20}/>;
    return <GridActionsCellItem label={'Delete'} icon={<DeleteIcon/>}
                                onClick={() => translationMemoryListActions.delete(translationMemory)}
                                disabled={isPending(translationMemory)}/>
}