import React from "react";
import {CollapseExpandButton, OverflowContainer} from "../../../globals/CommonComponents";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import AlertView from "../../AlertView";
import SegmentListControls from "./segments/SegmentListControls";
import {AlertType} from "../../../model/Alert";
import SegmentList from "./segments/SegmentList";
import {Stack} from "@mui/material";

type TopLeftFrameProperties = {
    isBottomHidden: boolean,
    setIsBottomHidden: (isHidden: boolean) => void;
    isFilterShown: boolean;
    gridApi: any;
}

export default function TopLeftFrame(props: TopLeftFrameProperties) {
    const toggleBottomLeftFrame = () => {
        props.setIsBottomHidden(!props.isBottomHidden);
    };

    return (
        <Stack sx={{ height: '100%',  overflow: 'hidden' }}>
            <OverflowContainer maxWidth={false}
                               style={{
                                   display: 'flex',
                                   flexDirection: 'column',
                                   height: '100%',
                                   paddingTop: '15px',
                                   paddingBottom: '44px',
                                   overflow: 'hidden',
                                   borderTop: !props.isFilterShown ? 'none' : '1px solid #d6d6d6',
                               }}

            >
                <AlertView type={AlertType.Editor}/>
                <SegmentListControls/>
                <SegmentList/>
            </OverflowContainer>
            <CollapseExpandButton
                isBottomHidden={props.isBottomHidden}
                onClick={toggleBottomLeftFrame}>
                <span className="button-text">{props.isBottomHidden ? 'SHOW INFO' : 'HIDE INFO'}</span>
                {drawCollapseButtonArrow(props.isBottomHidden)}
            </CollapseExpandButton>
        </Stack>

    );
}

function drawCollapseButtonArrow(isBottomHidden: boolean) {
    return (
        isBottomHidden ? (
            <ArrowDropUp className="arrow-icon"/>
        ) : (
            <ArrowDropDown className="arrow-icon"/>
        )
    )
}