import React, {useState} from "react";
import {Autocomplete, Button, Container, Grid, Stack, TextField} from "@mui/material";
import {NavigateFunction, useLoaderData, useNavigate} from "react-router-dom";
import pretranslateModes, {PretranslateMode} from "../../../model/PretranslateMode";
import {pretranslateAction} from "../../../flux/project/page/ProjectPageActions";
import Project from "../../../model/Project";
import projectPageStore from "../../../flux/project/page/ProjectPageStore";
import WorkflowStep from "../../../model/WorkflowStep";
import {CatFile} from "../../../model/CatFile";
import ListView from "../../common/ListView";
import {GridColDef} from "@mui/x-data-grid";
import Immutable from "immutable";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {catFileSelectActions} from "../../../flux/cat-files/select/CatFileSelectActions";
import CatFilesFilter from "../../../flux/cat-files/CatFilesFilter";
import {getCatFilesPath} from "../../../routes/project/tabs/CatFilesRoute";
import TranslationMemory from "../../../model/TranslationMemory";

// TODO do not use tm names -> use TM id inside TM model
export default function PretranslateView() {
    const projectState = projectPageStore.getState();
    const tmList = projectState.project.translationMemories;
    const workflow = getWorkflowStepsAsMap(projectState.project);
    const navigate = useNavigate();
    const loaderData = useLoaderData() as Project;
    const [tm, setTm] = useState<TranslationMemory | null>(null);
    const [pretranslateMode, setPretranslateMode] = useState<PretranslateMode | null>(null);
    const [workflowStep, setWorkflowStep] = useState<WorkflowStep | null>(null);
    const [minimalWordsAmount, setMinimalWordsAmount] = useState<number | null>(null);
    catFileSelectActions.fetch(new CatFilesFilter({projectId: projectState.project.id}));


    return (
        <Container maxWidth={false}>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete options={tmList.toArray()}
                                      getOptionLabel={(option: TranslationMemory) => option.name}
                                      renderInput={(params) =>
                                          <TextField {...params} label={"Translation Memory"} variant={"standard"}/>}
                                      value={tm}
                                      onChange={(_, value) =>
                                          setTm(value)}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete options={pretranslateModes}
                                      getOptionLabel={(option: PretranslateMode) => option.presentation}
                                      renderInput={(params) =>
                                          <TextField {...params} label={"Pretranslate mode"} variant={"standard"}/>}
                                      value={pretranslateMode}
                                      onChange={(_, value) =>
                                          setPretranslateMode(value)}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete options={Array.from(workflow.keys())}
                                      getOptionLabel={(option: string) => option}
                                      renderInput={(params) =>
                                          <TextField {...params} label={"Workflow"} variant={"standard"}/>}
                                      value={workflowStep?.name ?? null}
                                      onChange={(_, value) =>
                                          setWorkflowStep(value == null ? null : workflow.get(value)!)}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete options={Array.from({length: 10}, (_, i) => i + 1)}
                                      renderInput={(params) =>
                                          <TextField {...params} label={"Minimal amount of words in segment"}
                                                     variant={"standard"}/>}
                                      value={minimalWordsAmount}
                                      onChange={(_, value) =>
                                          setMinimalWordsAmount(value)}/>
                    </Grid>
                </Grid>
                <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button onClick={() => handleBackClicked(loaderData, navigate)}>Back</Button>
                    <Button variant={"contained"}
                            onClick={() => handlePretranslateClicked(
                                pretranslateMode,
                                tm,
                                loaderData,
                                workflowStep,
                                minimalWordsAmount,
                                navigate)}
                            disabled={!pretranslateMode || !tm}>
                        Pretranslate &gt;
                    </Button>
                </Stack>
                <ListView actions={catFileSelectActions} columns={columns}
                          getRowId={(row: CatFile) => row.id.toString()} navigateProps={null}
                          initialFilter={new CatFilesFilter()}/>
            </Stack>
        </Container>
    );
}

function getWorkflowStepsAsMap(project: Project) {
    return project
        .getWorkflowSteps()
        .reduce((map, value) => map.set(value.name as string, value),
            Immutable.Map<string, WorkflowStep>());
}

function handlePretranslateClicked(pretranslateMode: PretranslateMode | null,
                                   tm: TranslationMemory | null,
                                   project: Project,
                                   workflowStep: WorkflowStep | null,
                                   minimalWordsAmount: number | null,
                                   navigate: NavigateFunction) {
    if (pretranslateMode && tm) {
        const filesToPretranslate = catFileSelectActions
            .selected
            .map(file => Number(file.id))
            .toList();
        pretranslateAction(
            project.id,
            pretranslateMode,
            tm,
            workflowStep?.id ?? null,
            minimalWordsAmount,
            filesToPretranslate);
        navigate(getCatFilesPath(new CatFilesFilter({projectId: project.id})));
    }
}

function handleBackClicked(project: Project, navigate: NavigateFunction) {
    navigate(getCatFilesPath(new CatFilesFilter({projectId: project.id})));
}

const columns: GridColDef[] = [
    {
        field: 'file',
        headerName: 'File',
        type: 'string',
        flex: 5,
        valueGetter: (params: GridValueGetterParams<CatFile>) => params.row.name
    }
]

