import {ReduceStore} from "flux/utils";
import {TranslationMemoryEditorAction, TranslationMemoryEditorPayload} from "./TranslationMemoryEditorPayload";
import {Record} from "immutable";
import {dispatcher, Payload} from "../../Dispatcher";
import TranslationMemory from "../../../model/TranslationMemory";

interface ITranslationMemoryEditorState {
    tm: TranslationMemory,
    existed: TranslationMemory | null,
}

const TranslationMemoryEditorStateFactory = Record<ITranslationMemoryEditorState>({
    tm: new TranslationMemory(),
    existed: null
});

class TranslationMemoryEditorState extends TranslationMemoryEditorStateFactory {
}

export class TranslationMemoryEditorStore
    extends ReduceStore<TranslationMemoryEditorState, Payload> {
    constructor() {
        super(dispatcher);
    }

    getInitialState(): TranslationMemoryEditorState {
        return new TranslationMemoryEditorState();
    }

    reduce(state: TranslationMemoryEditorState, payload: TranslationMemoryEditorPayload): TranslationMemoryEditorState {
        switch (payload.action) {
            case TranslationMemoryEditorAction.SetTm:
                return state.set("tm", payload.tm);
            case TranslationMemoryEditorAction.SetExistedTm:
                return state.set("existed", payload.existed);
        }
        return state;
    }
}

const translationMemoryEditorStore = new TranslationMemoryEditorStore();
export default translationMemoryEditorStore;