import {LinearProgress, styled} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {Statistic} from "../../model/Statistic";
import {Language} from "../../model/Language";
import segmentListStore from "../../flux/segment/list/SegmentListStore";
import {LanguageList} from "../../model/LanguageList";
import SegmentListFilter from "../../flux/segment/list/SegmentListFilter";
import languageListStore from "../../flux/language/list/LanguageListStore";

const StatisticsContainer = styled(Box)({
    width: 261,
    marginLeft: 'auto',
    marginRight: '20px'
});
const TypographyContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
});

type TranslationStatisticsProps = {
    isOpen: boolean
}
const StatisticText = styled(Typography)({
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.8px',
    letterSpacing: '0.17px'
});

export default function TranslationStatistics(props: TranslationStatisticsProps) {
    const initialLanguageListState = languageListStore.getState();
    const initialSegmentListFilterState = segmentListStore.getState();

    const [catFile, setCatFile] = useState(segmentListStore.getState().filter.nonNullCatFile);
    const [supportedLanguages, setSupportedLanguages]
        = useState(initialLanguageListState.languages);
    const [filter, setFilter]
        = useState(initialSegmentListFilterState.filter);

    useEffect(() => {
        const segmentListListener = segmentListStore.addListener(() => {
            const state = segmentListStore.getState();
            setCatFile(state.filter.nonNullCatFile);
            setFilter(state.filter);

        });

        const languageListListener = languageListStore.addListener(() => {
            const state = languageListStore.getState();
            setSupportedLanguages(state.languages);
        });

        return () => {
            segmentListListener.remove();
            languageListListener.remove();

        }
    }, []);
    const targetLanguage: Language | null = getTargetLanguage(supportedLanguages, filter);

    const statistic = targetLanguage
        ? catFile.groupedStatistic.translatedByFirstWorkflow(targetLanguage)
        : new Statistic();

    if (!props.isOpen)
        return null;

    return (
        <StatisticsContainer>
            <LinearProgress color="inherit" variant={"determinate"} value={statistic.percent}/>
            <TypographyContainer>
                <StatisticText sx={{whiteSpace: 'nowrap'}}>
                    {statistic.workflow.name.replace(/^\w/, (c) => c.toUpperCase())}
                </StatisticText>
                <StatisticText sx={{marginLeft: 2, whiteSpace: 'nowrap'}}>
                    {statistic.presentation}
                </StatisticText>
            </TypographyContainer>
        </StatisticsContainer>
    );
}

function getTargetLanguage(supportedLanguages: LanguageList, filter: SegmentListFilter) {
    const foundLanguage = supportedLanguages.findByCode(filter.nonNullLanguage);
    if (!foundLanguage)
        return null;
    return foundLanguage;
}