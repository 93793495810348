import {ArrowBackIos} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Project from "../../model/Project";
import segmentListStore from "../../flux/segment/list/SegmentListStore";
import {getCatFilesPath} from "../../routes/project/tabs/CatFilesRoute";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";

type BackButtonProps = {
    isOpen: boolean
}

export default function BackButton(props: BackButtonProps) {
    const projectState = projectPageStore.getState();

    const navigate = useNavigate();
    const [currentProject, setCurrentProject]
        = useState<Project>(projectState.project);

    useEffect(() => {
        const projectPageListener = projectPageStore.addListener(() => {
            const state = projectPageStore.getState();
            const project = state.project
            setCurrentProject(project);
        });

        return () => {
            projectPageListener.remove();
        }
    }, []);
    if (!props.isOpen)
        return null;

    return (
        <>
            <Button onClick={() => handleBackClicked(navigate, currentProject)}
                    variant="menu2"
                    size="small"
                    sx={{
                        width: '300px',
                        gap: '5px',
                        justifyContent: 'flex-start',
                    }}>
                <ArrowBackIos fontSize="inherit" sx={{marginRight: '8px', fontSize: '0.75rem', fontWeight: 'normal'}}/>
                <Typography variant = "body2"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textTransform: 'none',
                        color: '#FFFFFF',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '20.8px',
                        letterSpacing: '0.15px',

                    }}
                >
                    {currentProject.name}
                </Typography>
            </Button>
        </>
    );
}


function handleBackClicked(navigate: NavigateFunction, project: Project) {
    const projectUrl = segmentListStore.getState().projectUrl;
    navigate(projectUrl ? projectUrl : getCatFilesPath(new CatFilesFilter({projectId: project.id})));
}
