import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {generateReportAction} from "../../flux/report/list/ReportListActions";
import {catFileSelectActions} from "../../flux/cat-files/select/CatFileSelectActions";
import CatFilesFilter from "../../flux/cat-files/CatFilesFilter";
import projectPageStore from "../../flux/project/page/ProjectPageStore";
import ListView from "../common/ListView";
import {CatFile} from "../../model/CatFile";
import {GridColDef} from "@mui/x-data-grid";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import Box from "@mui/material/Box";
import {SelectionModel} from "../../model/SelectionModel";
import {Page} from "../../model/Page";

type ReportSettingsProps = {
    isOpen: boolean,
    onClose: () => void
}

export default function ReportProjectFiles(props: ReportSettingsProps) {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const projectState = projectPageStore.getState();
    const [selection, setSelection] = useState(new SelectionModel());
    const [page, setPage] = useState(new Page<CatFile>());

    useEffect(() => {
        catFileSelectActions.fetch(new CatFilesFilter({projectId: projectState.project.id}));

        setIsOpen(props.isOpen);
        const catFilesListener = catFileSelectActions.store.addListener(() => {
            const state = catFileSelectActions.state;
            setPage(state.page);
            if (state.page)
                setSelection(catFileSelectActions.selection)

        })

        return () => {
            catFilesListener.remove();
        }
    }, [projectState.project.id, props.isOpen]);


    return (
        <Dialog open={isOpen}
                onClose={props.onClose}
                fullWidth
                maxWidth="md"
                BackdropProps={{style: {backgroundColor: 'transparent'}}}>
            <DialogTitle>Project Files</DialogTitle>
            <DialogContent>
                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.onClose();
                            handleGenerateReportClicked();
                        }}
                        style={{marginBottom: '18px', marginRight: '26px'}}>
                        GENERATE REPORT
                    </Button>
                </Stack>
                <ListView actions={catFileSelectActions}
                          columns={getColumns(selection, page)}
                          getRowId={(row: CatFile) => row.id.toString() + row.language}
                          navigateProps={null}
                          initialFilter={new CatFilesFilter()}
                          useNewSelectionModel={true}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onClose()
                }}
                        color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function handleGenerateReportClicked() {
    generateReportAction();
}

function getColumns(selection: SelectionModel, page: Page<CatFile>): GridColDef<CatFile>[] {
    return [{
        field: 'file',
        renderHeader: () => (
            <Stack direction={"row"}>
                File
                <Box display="flex" alignItems="center" marginLeft="8px">
                    {selection.countSelected(page.totalElements)}
                </Box>
                <Button variant={"text"}
                        onClick={() => {
                            catFileSelectActions.toggleSelectAllAction()
                        }}>
                    {`${selection.isSelectAll ? "Clear" : "Select"} All `}
                </Button>
            </Stack>
        ),
        type: 'string',
        flex: 5,
        sortable: false,
        valueGetter: (params: GridValueGetterParams<CatFile>) => params.row.name
    }
    ]
}