import {Map} from "immutable";

interface ISize {
    width: number,
    height: number
}

export class FontSizeCalc {

    public static Instance = new FontSizeCalc();

    private _cache = Map<string, ISize>();

    calcHeight(text: string, width: number, fontFamily: string, fontSize: string) {
        if (width === 0)
            return 0;

        const size = this.calcFontSize(fontFamily, fontSize);
        const textWidth = text.length * size.width;
        const rows = Math.ceil(textWidth / width);
        return rows * size.height;
    }

    calcFontSize(fontFamily: string, fontSize: string) {
        const key = fontFamily + fontSize;

        const size = this._cache.get(key);
        if (size)
            return size;

        const tester = document.createElement("span");

        document.body.appendChild(tester);
        tester.style.fontFamily = fontFamily;
        tester.style.fontSize = fontSize;
        tester.style.position = 'absolute';
        tester.style.visibility = 'hidden';
        tester.innerHTML = 'A'; // arbitrary char

        this._cache = this
            ._cache
            .set(key, {height: tester.clientHeight, width: tester.clientWidth})

        document.body.removeChild(tester);

        return this._cache.get(key)!;
    }
}