import {fetchManagers} from "../../../api/UserApi";
import {dispatcher} from "../../Dispatcher";
import {setAdministrators, setUsersWithPermissions} from "./UsersListPayload";
import {fetchProjectUsers} from "../../../api/ProjectApi";

export function fetchManagersAction(): void {
    fetchManagers().then(administrators => dispatcher.dispatch(setAdministrators(administrators)));
}

export async function fetchProjectUsersAction(projectId: number) {
    const users = await fetchProjectUsers(projectId);
    dispatcher.dispatch(setUsersWithPermissions(users));
}