import {createBrowserRouter} from "react-router-dom";
import PrivateRoute from "./private/PrivateRoute";
import Root from "./Root";
import ErrorPage from "./ErrorRoute";
import {fetchSupportedLanguagesAction} from "../flux/language/list/LanguagesListActions";
import TranslationMemoriesRoute, {
    getTranslationMemoriesPath,
    TranslationMemoriesId,
    TranslationMemoriesPath
} from "./TranslationMemoriesRoute";
import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";
import {translationMemoryListActions} from "../flux/translation-memory/list/TranslationMemoryListActions";
import {Link} from "@mui/material";
import GlossariesRoute, {getGlossariesPath, GlossariesId, GlossariesPath} from "./GlossariesRoute";
import GlossaryFilter from "../flux/glossary/GlossaryFilter";
import {glossaryListActions} from "../flux/glossary/list/GlossaryListActions";
import PlaceholdersSetsRoute, {PlaceholdersId, PlaceholdersPath} from "./placeholders/PlaceholdersSetsRoute";
import {fetchPlaceholdersSetListAction} from "../flux/placeholders-set/list/PlaceholdersSetListActions";
import NewPlaceholdersSetRoute, {
    NewPlaceholdersSetId,
    NewPlaceholdersSetPath
} from "./placeholders/NewPlaceholdersSetRoute";
import {
    fetchPlaceholdersSetAction,
    newPlaceholdersSetAction
} from "../flux/placeholders-set/editor/PlaceholdersSetEditorActions";
import PlaceholdersSetRoute, {PlaceholdersSetId, PlaceholdersSetPath} from "./placeholders/PlaceholdersSetRoute";
import placeholdersSetEditorStore from "../flux/placeholders-set/editor/PlaceholdersSetEditorStore";
import PlaceholdersSet from "../model/PlaceholdersSet";
import ProjectsRoute, {ProjectsId, ProjectsPath} from "./project/ProjectsRoute";
import {fetchProjectListAction} from "../flux/project/list/ProjectListActions";
import NewProjectRoute, {NewProjectId, NewProjectPath} from "./project/NewProjectRoute";
import {editNewProjectAction} from "../flux/project/editor/ProjectEditorActions";
import ProjectRoute, {ProjectId, ProjectPath} from "./project/ProjectRoute";
import Project from "../model/Project";
import {fetchProjectAction} from "../flux/project/page/ProjectPageActions";
import CatFilesRoute, {CatFilesId, CatFilesPath, getCatFilesPath} from "./project/tabs/CatFilesRoute";
import ProjectPlaceholdersRoute, {
    ProjectPlaceholdersId,
    ProjectPlaceholdersPath
} from "./project/tabs/ProjectPlaceholdersRoute";
import PermissionsRoute, {PermissionsId, PermissionsPath} from "./project/tabs/PermissionsRoute";
import ProjectTranslationMemoriesRoute, {
    ProjectTranslationMemoriesId,
    ProjectTranslationMemoriesPath
} from "./project/tabs/ProjectTranslationMemoriesRoute";
import ProjectGlossariesRoute, {
    ProjectGlossariesId,
    ProjectGlossariesPath
} from "./project/tabs/ProjectGlossariesRoute";
import ActivityRoute, {ActivityId, ActivityPath} from "./project/tabs/ActivityRoute";
import ProjectSearchRoute, {ProjectSearchId, ProjectSearchPath} from "./project/ProjectSearchRoute";
import PretranslateRoute, {PretranslateId, PretranslatePath} from "./project/PretranslateRoute";
import CatFilesFilter from "../flux/cat-files/CatFilesFilter";
import {initSegmentListEditor} from "../flux/segment/list/SegmentListActions";
import ProjectReportRoute, {ProjectReportId, ProjectReportPath} from "./project/ProjectReportRoute";
import {ProjectReportFilter} from "../model/ReportFilters";
import {setReportListFilterAction} from "../flux/report/list/ReportListActions";
import React from "react";
import EditorRoute, {EditorId, EditorPath} from "./EditorRoute";
import {fetchManagersAction} from "../flux/users/list/UsersListActions";
import TenantsRoute, {TenantsId, TenantsPath} from "./admin/TenantsRoute";
import {tenantListActions} from "../flux/tenant/TenantListActions";
import NullFilter from "../flux/common/NullFilter";
import {AdminPaneId, AdminPanePath} from "./admin/AdminPaneRoute";
import AdminPane from "../components/admin/AdminPaneView";
import AdminUsersRoute, {AdminUsersId, AdminUsersPath} from "./admin/AdminUsersRoute";
import {adminUserListActions} from "../flux/users/select/AdminUserListActions";
import {vendorListActions} from "../flux/vendor/VendorListActions";

import VendorsListRoute, {getVendorsListPath, VendorsListPath} from "./vendor/VendorsListRoute";
import VendorRoute, {VendorPath} from "./vendor/VendorRoute";
import {Vendor} from "../model/Vendor";
import {fetchVendorAction} from "../flux/vendor/editor/VendorEditorActions";
import NewVendorRoute, {NewVendorPath} from "./vendor/NewVendorRoute";
import UsersListFilter from "../flux/users/UsersListFilter";

export default function getRouter() {
    return createBrowserRouter([
        {
            path: "/",
            element: <PrivateRoute children={<Root/>}/>,
            errorElement: <ErrorPage/>,
            loader: () => {
                fetchSupportedLanguagesAction();
                fetchManagersAction();
                return null;
            },
            children: [
                {
                    id: TranslationMemoriesId,
                    path: TranslationMemoriesPath,
                    loader: async ({request}) => {
                        const searchParams = (new URL(request.url)).searchParams;

                        const filter = TranslationMemoryFilter.fromSearchParams(null, searchParams);
                        await translationMemoryListActions.setFilter(filter)
                        await translationMemoryListActions.fetch(filter);
                        return null;
                    },
                    element: <TranslationMemoriesRoute/>,
                    handle: {
                        crumb: () => <Link href={getTranslationMemoriesPath(new TranslationMemoryFilter())}
                                           key={"translation-memories-link"}>
                            Translation memories
                        </Link>
                    }
                },
                {
                    id: GlossariesId,
                    path: GlossariesPath,
                    loader: async ({request}) => {
                        const searchParams = (new URL(request.url)).searchParams;

                        const filter = GlossaryFilter.fromSearchParams(
                            null,
                            null,
                            searchParams);
                        await glossaryListActions.setFilter(filter);
                        await glossaryListActions.fetch(filter);
                        return null;
                    },
                    element: <GlossariesRoute/>,
                    handle: {
                        crumb: () => <Link href={getGlossariesPath(new GlossaryFilter())} key="glossaries-link">
                            Glossaries
                        </Link>
                    }
                },
                {
                    id: PlaceholdersId,
                    path: PlaceholdersPath,
                    loader: () => {
                        fetchPlaceholdersSetListAction();
                        return null;
                    },
                    element: <PlaceholdersSetsRoute/>,
                    handle: {
                        crumb: () => <Link href={PlaceholdersPath} key="placeholders-link">Placeholders</Link>
                    }
                },
                {
                    id: NewPlaceholdersSetId,
                    path: NewPlaceholdersSetPath,
                    loader: () => {
                        newPlaceholdersSetAction();
                        return null;
                    },
                    element: <NewPlaceholdersSetRoute/>
                },
                {
                    id: PlaceholdersSetId,
                    path: PlaceholdersSetPath,
                    loader: async ({params}) => {
                        const id = params.placeholdersSetId;
                        if (!id)
                            return null;

                        await fetchPlaceholdersSetAction(Number(id));
                        return placeholdersSetEditorStore.getState().placeholdersSet;
                    },
                    element: <PlaceholdersSetRoute/>,
                    handle: {
                        crumb: (data: PlaceholdersSet) => [
                            <Link href={PlaceholdersPath} key="placeholders-link">Placeholders</Link>,
                            <Link href={"#"} key={"current-placeholders-set-link"}>{data.name}</Link>
                        ]
                    }
                },
                {
                    id: VendorsListPath,
                    path: VendorsListPath,
                    loader: async () => {
                        await vendorListActions.fetch(new NullFilter());
                        return null;
                    },
                    element: <VendorsListRoute/>,
                    handle: {
                        crumb: () => <Link href={getVendorsListPath()} key="vendors-link">
                            Vendors
                        </Link>
                    }
                },
                {
                    id: VendorPath,
                    path: VendorPath,
                    loader: async ({params}) => {
                        const id = params.id;
                        if (!id)
                            return new Vendor();

                        return await fetchVendorAction(Number(id));
                    },
                    element: <VendorRoute/>,
                    handle: {
                        crumb: (data: Vendor) => [
                            <Link href={VendorsListPath} key={"vendors-list-link"}>Vendors</Link>,
                            <Link href={"#"} key={"current-vendor-link"}>{data.name}</Link>
                        ]
                    },
                },
                {
                    id: NewVendorPath,
                    path: NewVendorPath,
                    loader: async () => {
                        return null;
                    },
                    element: <NewVendorRoute/>,
                    handle: {
                        crumb: () => [
                            <Link href={VendorsListPath} key={"vendors-list-link"}>Vendors</Link>,
                            <Link href={"#"} key={"new-vendor-link"}>New</Link>
                        ]
                    },
                },
                {
                    id: ProjectsId,
                    path: ProjectsPath,
                    loader: () => {
                        fetchProjectListAction();
                        return null;
                    },
                    element: <ProjectsRoute/>,
                    handle: {
                        crumb: () => <Link href={ProjectsPath} key={"projects-link"}>Projects</Link>
                    }
                },
                {
                    id: NewProjectId,
                    path: NewProjectPath,
                    loader: () => {
                        editNewProjectAction();
                        return null;
                    },
                    element: <NewProjectRoute/>
                },
                {
                    id: ProjectId,
                    path: ProjectPath,
                    element: <ProjectRoute/>,
                    loader: async ({params}) => {
                        const projectId = params.id;
                        if (!projectId)
                            return new Project();

                        return await fetchProjectAction(Number(projectId));
                    },
                    handle: {
                        crumb: (data: Project) => [
                            <Link href={ProjectsPath} key={"projects-link"}>Projects</Link>,
                            <Link href={"#"} key={"current-project-link"}>{data.name}</Link>
                        ]
                    },
                    children: [
                        {
                            index: true,
                            element: <CatFilesRoute/>
                        },
                        {
                            id: CatFilesId,
                            path: CatFilesPath,
                            element: <CatFilesRoute/>
                        },
                        {
                            id: ProjectPlaceholdersId,
                            path: ProjectPlaceholdersPath,
                            element: <ProjectPlaceholdersRoute/>,
                            loader: async () => {
                                await fetchPlaceholdersSetListAction();
                                return null;
                            }
                        },
                        {
                            id: PermissionsId,
                            path: PermissionsPath,
                            element: <PermissionsRoute/>
                        },
                        {
                            id: ProjectTranslationMemoriesId,
                            path: ProjectTranslationMemoriesPath,
                            element: <ProjectTranslationMemoriesRoute/>
                        },
                        {
                            id: ProjectGlossariesId,
                            path: ProjectGlossariesPath,
                            element: <ProjectGlossariesRoute/>
                        },
                        {
                            id: ActivityId,
                            path: ActivityPath,
                            element: <ActivityRoute/>
                        }
                    ]
                },
                {
                    id: ProjectSearchId,
                    path: ProjectSearchPath,
                    element: <ProjectSearchRoute/>
                },
                {
                    id: PretranslateId,
                    path: PretranslatePath,
                    element: <PretranslateRoute/>,
                    loader: async ({params}) => {
                        const id = params.id;
                        if (!id)
                            return null;
                        return await fetchProjectAction(Number(id));
                    },
                    handle: {
                        crumb: (data: Project) => {
                            const id = data ? data.id : 0;
                            return [
                                <Link href={ProjectsPath} key={"projects-link"}>Projects</Link>,
                                <Link href={getCatFilesPath(new CatFilesFilter({projectId: id}))}
                                      key={"current-project-link"}>
                                    {data.name}
                                </Link>,
                                <Link href={"#"} key={"pretranslation-link"}>Pretranslation</Link>
                            ];
                        }
                    }
                },
                {
                    id: EditorId,
                    path: EditorPath,
                    element: <EditorRoute/>,
                    loader: ({params, request}) => {
                        const projectId = params.projectId;
                        const language = params.language;
                        const fileId = params.fileId
                        const positionParam = params.position;
                        if (!projectId || !language || !fileId || !positionParam)
                            return null;

                        let position = positionParam === "null" ? null : Number(positionParam);
                        if (position && position !== 0)
                            position -=1; // need's for properly smartEdit works

                        const searchParams = (new URL(request.url)).searchParams;
                        initSegmentListEditor(
                            Number(projectId),
                            language,
                            Number(fileId),
                            position,
                            searchParams);
                        return null;
                    }
                },
                {
                    id: ProjectReportId,
                    path: ProjectReportPath,
                    loader: ({params}) => {
                        const filter = ProjectReportFilter.ofProject(Number(params.id));
                        setReportListFilterAction(filter);
                        return null;
                    },
                    element: <ProjectReportRoute/>
                },
                {
                    id: AdminPaneId,
                    path: AdminPanePath,
                    loader: async () => {
                        await tenantListActions.fetch(new NullFilter());
                        await adminUserListActions.fetch(new UsersListFilter());
                        await vendorListActions.fetch(new NullFilter());
                        return null;
                    },
                    element: <AdminPane/>,
                    handle: {
                        crumb: () => <Link href={AdminPanePath}
                                           key={"admin-pane-link"}>
                            Admin pane
                        </Link>
                    },
                    children: [
                        {
                            index: true,
                            element: <AdminUsersRoute/>
                        },
                        {
                            id: AdminUsersId,
                            path: AdminUsersPath,
                            element: <AdminUsersRoute/>
                        },
                        {
                            id: TenantsId,
                            path: TenantsPath,
                            element: <TenantsRoute/>
                        }
                    ]
                },
            ]
        }
    ]);
}