import {Element, SlateEntityType} from "./SlateModels";
import {BaseText, Descendant, Text} from "slate";
import {textToUserPresentation} from "../../globals/Utils";

export class SlateSerializer {
    serialize(nodes: Descendant[]): string {
        let result = "";
        for (const node of nodes)
            result += this.serializeNode(node);

        return result;
    }

    serializeRaw(nodes: Descendant[]): string {
        let result = "";
        for (const node of nodes)
            result += this.serializeNodeRaw(node);

        return result;
    }

    deserialize(text: string) {
        const parsed: Descendant[] = []
        let processed = "";

        const startSpaces = text.length - text.trimStart().length;
        const endSpaces = text.length - text.trimEnd().length;

        this.addSpaces(parsed, startSpaces);
        text
            .trim()
            .split(/(<plch id="\d+">[\s\S]+?<\/plch>)|(&[a-zA-Z]+;)|(\u00A0)/)
            .filter(value => value)
            .forEach((part, index) => {
                const element = this.process(part, index);
                parsed.push(element);
                processed += part;
            });
        this.addSpaces(parsed, endSpaces);

        const result: Descendant[] = [];
        if (parsed.length === 0)
            result.push({text: ""});
        else {
            if (!('text' in parsed[0]))
                result.push({text: ""});
            for (let i = 0; i < parsed.length - 1; ++i) {
                result.push(parsed[i]);
                if (!('text' in parsed[i]) && !('text' in parsed[i + 1]))
                    result.push({text: ""});
            }
            result.push(parsed[parsed.length - 1]);
            if (!('text' in parsed[parsed.length - 1]))
                result.push({text: ""});
        }

        return {
            children: [{
                type: SlateEntityType.Paragraph,
                children: result
            }] as Descendant[]
        }
    }

    // TODO: remove unnecessary converter. For example, getting from backend raw source
    decodeText(source: string) {
        let decoded = "";
        let currentPos = 0;
        for (const match of source.matchAll(placeholderTagRe)) {
            const matchIndex = match.index;
            if (matchIndex === undefined)
                continue;
            const endPos = matchIndex + match[0].length;
            decoded += source.substring(currentPos, matchIndex) + match[2];
            currentPos = endPos;
        }
        decoded += source.substring(currentPos);
        return decoded;
    }

    getSortedPlaceholderIds(source: string): number[] {
        const placeholders: number[] = [];
        for (const match of source.matchAll(placeholderTagRe)) {
            const id = Number(match[1]);
            if (!placeholders.includes(id))
                placeholders.push(id);
        }
        return placeholders;
    }

    private serializeNode(node: Descendant | null): string {
        if (!node)
            return "";
        if (Text.isText(node))
            return node.text;

        switch (node.type) {
            case SlateEntityType.EscapedText:
                return node.rawText;
            case SlateEntityType.Placeholder:
                return node.content;
            case SlateEntityType.Paragraph: {
                let result = "";
                for (const child of node.children)
                    result += this.serializeNode(child);
                return result;
            }
            default:
                return "";
        }
    }

    private serializeNodeRaw(node: Descendant | null): string {
        if (!node)
            return "";
        if (Text.isText(node))
            return node.text;

        switch (node.type) {
            case SlateEntityType.EscapedText:
            case SlateEntityType.Placeholder:
                return node.rawText;
            case SlateEntityType.Paragraph: {
                let result = "";
                for (const child of node.children)
                    result += this.serializeNodeRaw(child);
                return result;
            }
            default:
                return "";
        }
    }

    private process(part: string, index: number) {
        const placeholderMatch = part.match(/<plch id="(\d+)">([\s\S]+?)<\/plch>/)
        if (placeholderMatch != null)
            return this.asPlaceholder(placeholderMatch, index);

        const escapeMatch = part.match(/(&[a-zA-Z]+;)|(\u00A0)/);
        if (escapeMatch != null) {
            return {
                type: SlateEntityType.EscapedText,
                rawText: escapeMatch[0],
                children: [{text: textToUserPresentation(escapeMatch[0])}]
            } as Element;
        }

        return {
            text: part
        } as BaseText;
    }

    private addSpaces(parsed: Descendant[], n: number) {
        for (let i = 0; i < n; ++i) {
            parsed.push({
                type: SlateEntityType.EscapedText,
                rawText: ' ',
                children: [{text: '·'}]
            } as Element);
        }
    }

    private asPlaceholder(match: RegExpMatchArray, index: number) {
        const id = Number(match[1]);
        return {
            type: SlateEntityType.Placeholder,
            id: id,
            content: match[2],
            rawText: match[0],
            order: index,
            render: true,
            children: [{
                text: match[1]
            }]
        } as Element;
    }
}

const PLACEHOLDER_TAG = "plch";

const placeholderTagRe = new RegExp(
    `<${PLACEHOLDER_TAG} id="(\\d+)">([\\s\\S]+?)<\\/${PLACEHOLDER_TAG}>`,
    'g');

const slateSerializer = new SlateSerializer();
export default slateSerializer;