import React, {useState} from "react";
import {OverflowContainer, SimpleTab} from "../../../globals/CommonComponents";
import ExistingTranslationsView from "./existing-translations/ExistingTranslationsView";
import {Container, Stack, Tabs} from "@mui/material";
import {Language} from "../../../model/Language";

export enum ExistingTranslationsTabName {
    All = "All",
    TM = "TM",
    TB = "TB"
}

type TopRightFrameProperties = {
    targetLanguage: Language | null
}

export default function MiddleRightFrame(props: TopRightFrameProperties) {
    const [currentTab, setCurrentTab]
        = useState(ExistingTranslationsTabName.All);

    const handleTabChanged = (value: ExistingTranslationsTabName) => {
        setCurrentTab(value);
    }

    return (
        <OverflowContainer maxWidth="sm" sx={{margin:0,maxWidth: '500px'}}>
            <Stack sx={{height: '100vh',display: 'flex',maxWidth: '500px'}}>
                <Container>
                    <Tabs value={currentTab} onChange={(_event, value) =>
                        handleTabChanged(value)}>
                        <SimpleTab label={"ALL"} value={ExistingTranslationsTabName.All}/>
                        <SimpleTab label={"TM"} value={ExistingTranslationsTabName.TM}/>
                        <SimpleTab label={"TB"} value={ExistingTranslationsTabName.TB}/>
                    </Tabs>
                </Container>
                <ExistingTranslationsView currentTab={currentTab} targetLanguage={props.targetLanguage}/>
            </Stack>
        </OverflowContainer>
    );
}
