import React, {useEffect, useState} from "react";
import {Segment} from "../../../../model/Segment";
import {styled} from "@mui/material/styles";
import {Checkbox, Container, Icon, LinearProgress, Stack, Tooltip} from "@mui/material";
import {
    editorPosition,
    editorRawText,
    editorText,
    getUpdatedHistory,
    positionToSelection,
    withInlines,
    withPreviousState
} from "../../../../utils/slate/SlateUtils";
import Typography from "@mui/material/Typography";
import {Editable, ReactEditor, Slate, withReact} from "slate-react";
import {BaseEditor, createEditor, Editor, Range, Transforms} from "slate";
import {isKeyHotkey} from "is-hotkey";
import Translation from "../../../../model/Translation";
import {
    approveTranslationAction,
    saveTranslationAction,
    setEditorCursorPositionAction,
    setTargetEditorAction, updateTranslationFromResourceAction
} from "../../../../flux/segment/editor/SegmentEditorActions";
import {List} from "immutable";
import segmentStore from "../../../../flux/segment/editor/SegmentEditorStore";
import {RenderedTargetElement} from "./rendering/RenderedTargetElement";
import {RenderedText} from "./rendering/RenderedText";
import {RenderedSourceElement} from "./rendering/RenderedSourceElement";
import {withHistory} from "slate-history";
import {ClipboardEvent} from "../../../../globals/Types";
import slateSerializer from "../../../../utils/slate/SlateSerializer";

import {WorkflowStepModel} from "../../../../model/WorkflowStepModel";
import {
    toggleSegmentSelectionAction,
    updateSegmentTranslationAction
} from "../../../../flux/segment/list/SegmentListActions";
import LockIcon from '@mui/icons-material/Lock';
import {dateTimeFormat} from "../../../../globals/Utils";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {LoadingButton} from "@mui/lab";
import segmentEditorStore from "../../../../flux/segment/editor/SegmentEditorStore";
import WorkflowStep from "../../../../model/WorkflowStep";
import projectPageStore from "../../../../flux/project/page/ProjectPageStore";
import Box from "@mui/material/Box";

type SegmentViewProps = {
    onClick: (event: React.MouseEvent, editor: Editor) => void,
    onShiftSegment: (shift: number) => void,
    segment: Segment,
    position: number,
    isSelected: boolean,
    style: React.CSSProperties,
}

type SegmentsTableRowProps = {
    isEditing: boolean,
    isLocked: boolean
}

const SegmentsTableRow = styled(Stack, {
    shouldForwardProp: propName => propName !== 'isEditing' && propName !== 'isLocked'
})<SegmentsTableRowProps>((props) => ({
    backgroundColor: rowColor(props),
    '&:hover': {
        backgroundColor: '#FAFBFC'
    }
}));

function rowColor(props: SegmentsTableRowProps) {
    if (props.isLocked)
        return 'rgba(198,198,224,0.6)';

    if (props.isEditing)
        return '#f4f5f7';

    return '#ffffff';
}

const SegmentCell = styled(Container)({
    textAlign: 'center',
    verticalAlign: 'top',
    padding: '5px 5px !important'
});

const ButtonWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    gap:'10px'
});

const SegmentSourceCell = styled(Container)({
    verticalAlign: 'top',
    padding: '5px 5px !important'
});

const CustomEditable = styled(Editable)({
    textAlign: 'start',
});

export default function SegmentView(props: SegmentViewProps) {
    const state = segmentEditorStore.getState();

    const translation = props.segment.translation;
    const currentWorkflowStepName = translation.workflowStep ? translation.workflowStep.name : '';
    const isLastStep = translation.nextWorkflowStep.isEmpty();
    const firstStep = projectPageStore.getState().project.getFirstStep();
    const deserializedTarget = slateSerializer.deserialize(translation.target);
    const [sourceEditor] = useState(() => withInlines(withReact(createEditor())));
    const [cursorPosition, setCursorPosition] = useState<number | null>(null)
    const [isEditing, setIsEditing] = useState(props.segment.id === state.segment?.id)

    const [targetEditor] = useState(() =>
        withInlines(withPreviousState(
            withReact(withHistory(createEditor())),
            getUpdatedHistory(translation.history, deserializedTarget.children))));

    useEffect(() => {
        const segmentEditorListener = segmentEditorStore.addListener(() => {
            const state = segmentEditorStore.getState();
            setIsEditing(props.segment.id === state.segment?.id)
            setCursorPosition(segmentEditorStore.getState().cursorPosition);
        });

        return () => segmentEditorListener.remove();
    });

    useEffect(() => {
        if (!isEditing)
            return;

        setTargetEditorAction(targetEditor);

        // targetEditor.operations.length should be 0, else ReactEditor.focus(targetEditor)
        // throws Error, when filter changed
        if (!ReactEditor.isFocused(targetEditor) && targetEditor.operations.length == 0)
            ReactEditor.focus(targetEditor);

        const selection = positionToSelection(cursorPosition, targetEditor);
        if (selection != null)
            Transforms.select(targetEditor, selection);
    }, [isEditing, cursorPosition]);

    if (props.segment.equals(Segment.Empty))
        return drawLoadingSegment(props.position, isEditing, translation.isLocked, props.style, props.segment);

    return (
        <SegmentsTableRow onClick={(e) => {
            props.onClick(e, targetEditor)
            const selection = targetEditor.selection;

            const isSelectedPosition = selection?.anchor == selection?.focus;
            if (isSelectedPosition)
                setEditorCursorPositionAction(editorPosition(targetEditor) + 1)
        }}
                          onKeyDown={e =>
                              handleKeyDown(e, sourceEditor, targetEditor, props)}
                          isEditing={isEditing}
                          isLocked={translation.isLocked}
                          style={{...props.style, ...{wordBreak: 'break-all'}}}
                          direction={"row"}
        >
            {drawSelector(props.isSelected, props.position)}
            <SegmentCell style={{minWidth: '50px',width:'50px', whiteSpace: 'nowrap'}}>
                <Stack>
                    <Typography textAlign={"left"}>{props.segment.order + 1}</Typography>
                    {drawComments(props.segment)}
                    {drawLock(translation)}
                </Stack>
            </SegmentCell>
            <SegmentSourceCell style={{flexGrow: 1}}>
                <Slate editor={sourceEditor}
                       initialValue={slateSerializer.deserialize(props.segment.source).children}>
                    <CustomEditable readOnly={true}
                                    renderElement={(renderProps) =>
                                        <RenderedSourceElement children={renderProps.children}
                                                               element={renderProps.element}
                                                               attributes={renderProps.attributes}
                                                               editor={targetEditor}
                                                               onPlaceholder={placeholder => {
                                                                   Transforms.insertNodes(targetEditor, placeholder)
                                                                   setEditorCursorPositionAction(editorPosition(targetEditor) + 1)
                                                               }}
                                                               segment={props.segment}/>}
                                    renderLeaf={(props) =>
                                        <RenderedText children={props.children} attributes={props.attributes}
                                                      leaf={props.leaf} text={props.text}/>}
                                    onCopy={(event) => editorOnCopy(event, sourceEditor)}
                    />
                </Slate>
                <Typography style={{marginTop: '0px', paddingTop: '0px', lineHeight: 1.5}} fontSize={12}
                            color={"#97A0AF"}>{props.segment.sourceId}</Typography>
            </SegmentSourceCell>
            <SegmentCell style={{flexGrow: 1}}>
                <Slate editor={targetEditor}
                       initialValue={deserializedTarget.children}
                       onChange={() => handleTargetChanged(props.segment, translation.languageCode, isLastStep, targetEditor)}>
                    <CustomEditable style={{
                        border: isEditing ? '1px solid #B3BAC5' : 'none',
                        borderRadius: "4px",
                        padding: "8px",
                        background: isEditing ? '#FFFFFF' : 'none',
                        height: `calc(${props.style.height}px - 20px)`,
                        width: "100%",
                        flexGrow: 1,
                        outline: "none",
                    }}
                                    renderElement={(props) =>
                                        <RenderedTargetElement children={props.children} element={props.element}
                                                               attributes={props.attributes}/>}
                                    renderLeaf={(props) =>
                                        <RenderedText children={props.children} attributes={props.attributes}
                                                      leaf={props.leaf}
                                                      text={props.text}/>}
                                    onDoubleClick={() => repairDoubleClickSelect()}
                                    readOnly={translation.isLocked}
                                    onKeyDown={(e) => handleEditorKeyDown(e, targetEditor)}
                                    onCopy={(event) => editorOnCopy(event, targetEditor)}
                    />
                </Slate>
            </SegmentCell>
            <SegmentCell style={{
                minWidth: '150px',
                width: '400px',
                whiteSpace: 'nowrap',
                paddingRight: '10px',
                paddingLeft: 0,
            }}>
                <ButtonWrapper>
                    {drawApproveTranslation(props, currentWorkflowStepName, isLastStep, targetEditor)}
                    {drawWarnings(translation, firstStep)}
                </ButtonWrapper>
            </SegmentCell>
        </SegmentsTableRow>
    );
}

function completeEditing(editor: ReactEditor,
                         props: SegmentViewProps,
                         isApprove: boolean) {
    const text = editorText(editor);
    if (text === "")
        return;

    if (isApprove)
        approveTranslationAction(props.segment, text);
    else
        saveTranslationAction(props.segment, text, null, null);
}

function editorOnCopy(event: ClipboardEvent, editor: BaseEditor) {
    event.preventDefault();
    const text = slateSerializer.serialize(editor.getFragment());
    event.clipboardData.setData("text/plain", text);
}

function handleEditorKeyDown(e: React.KeyboardEvent<HTMLElement>, editor: Editor) {
    const {selection} = editor;
    const {nativeEvent} = e;

    if (isKeyHotkey('enter', nativeEvent)) {
        e.preventDefault();
        return;
    }

    if (selection && Range.isCollapsed(selection)) {
        if (isKeyHotkey('left', nativeEvent)) {
            e.preventDefault();
            Transforms.move(editor, {
                unit: 'offset',
                reverse: true
            });
            return;
        }
        if (isKeyHotkey('right', nativeEvent)) {
            e.preventDefault();
            Transforms.move(editor, {
                unit: 'offset'
            });
            return;
        }
        if (isKeyHotkey('backspace', nativeEvent)) {
            e.preventDefault();
            Transforms.delete(editor, {
                at: selection.anchor,
                distance: 1,
                unit: 'character',
                reverse: true
            });
            return;
        }
    }
}

function handleKeyDown(e: React.KeyboardEvent,
                       sourceEditor: Editor,
                       targetEditor: Editor,
                       props: SegmentViewProps) {
    const available = hotKeys.filter(value => value.isHotkey(e));
    if (available.isEmpty())
        return;

    const sourceText = editorText(sourceEditor);
    const targetText = editorText(targetEditor);
    e.preventDefault();

    available.first()!.action({
        keyEvent: e,
        segmentViewProps: props,
        sourceText: sourceText,
        targetText: targetText,
        editor: targetEditor
    });
}

function handleTargetChanged(segment: Segment, languageCode: string, isLastStep: boolean, editor: Editor) {
    if (!isLastStep)
        return;
    const editingOperations = editor.operations.filter(op => op.type !== "set_selection");

    if (editingOperations.length <= 0)
        return;

    const translation = segment.translation
        .set("target", editorRawText(editor))
        .set("workflowStep", segment.translation.previousWorkflowStep)
        .set("nextWorkflowStep", segment.translation.workflowStep)
        // mock previous workflow step. Reason: it doesn't use.
        .set("previousWorkflowStep", new WorkflowStep());

    updateSegmentTranslationAction(segment.id, translation);
    saveTranslationAction(segment, editorRawText(editor), segmentEditorStore.getState().cursorPosition, editor.history)
}

/**
 *  Workaround for error: 'Failed to execute 'setEnd' on 'Range': There is no child at offset {}'
 *  Open issue: https://github.com/ianstormtaylor/slate/issues/5435
 *              https://github.com/udecode/plate/issues/2883
 *  Error description:  Slate crashes and displays the error message when you select text followed by a readOnly element (placeholder) and then press the delete key.
 *                      This occurs on word double-click selection, which partially selects the placeholder node
 *  Workaround description: To address the fix, the selection range is adjusted by setting the endOffset to the end of the selected text node.
 */
function repairDoubleClickSelect() {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const parentNode = range.commonAncestorContainer;
        const selectedNode = range.startContainer;

        if (selectedNode === null || parentNode === null)
            return;
        if (range.endContainer.nodeType != range.startContainer.nodeType) {
            const cloneRange = range.cloneRange();
            const endOffset = cloneRange.startOffset + cloneRange.toString().length;
            range.setEnd(selectedNode, endOffset);
        }
    }
}

function drawLock(translation: Translation) {
    const lock = translation.lock;

    if (lock == null)
        return null;

    const date = dateTimeFormat(lock.created);
    return (
        <Tooltip title={`Locked by ${lock.username} at ${date}`}>
            <LockIcon color={"primary"}/>
        </Tooltip>)
}

function drawComments(segment: Segment) {
    if (segment.commentsCount <= 0)
        return null;

    return (
        <Stack direction={"row"} alignItems={"center"}>
            <Icon>
                <img src={"/003-24x24.svg"} alt={"comments"} width={21} height={20}/>
            </Icon>
            <Typography fontSize={14} fontWeight={"bold"} color={"#158aff"}>
                {segment.commentsCount}
            </Typography>
        </Stack>
    )
}

function drawWarnings(translation: Translation, firstStep: WorkflowStepModel) {
    const hasErrors = translation.markupErrorModels.size > 0;
    const tryToApprove = translation.tryToApprove;
    const notFirstStep = firstStep && translation.workflowStep.id !== firstStep.id;

    if (hasErrors && (tryToApprove || notFirstStep)) {
        return <Tooltip title={translation.markupErrorModels.map(errorModel => errorModel.error).join('\n')}>
            <ErrorOutline color={'error'}/>
        </Tooltip>;
    }
}

function drawSelector(isSelected: boolean, position: number) {
    return (
        <SegmentCell sx={{minWidth:'35px', width: '35px', position: 'relative', display:"flex",paddingLeft: '0 !important', alignItems: 'flex-start'}}>
            <Checkbox
                sx={{padding: 0}}
                id={`segment-selected-checkbox-${position}`}
                onChange={() => toggleSegmentSelectionAction(position)}
                checked={isSelected}/>
        </SegmentCell>
    )
}

function drawApproveTranslation(props: SegmentViewProps,
                                nextWorkflowStepName: string,
                                isLastStep: boolean,
                                targetEditor: ReactEditor) {
    const translation = props.segment.translation;
    return (
        <LoadingButton
            variant="contained"
            color="primary"
            disabled={translation.isLocked || isLastStep}
            onClick={() => completeEditing(targetEditor, props, true)}
            id={`approve-segment-${props.segment.id}`}
            sx={{
                height: '30px',
                padding: '4px 10px',
                margin: 0,
                display: 'inline-flex',
                justifyContent: 'center',
            }}
        >
            {returnButtonsWorkflowStep(nextWorkflowStepName)}
        </LoadingButton>
    )
}

function returnButtonsWorkflowStep(nextWorkflowStepName: string) {
    return nextWorkflowStepName.toUpperCase();
}

function drawLoadingSegment(position: number, isEditing: boolean, isLocked: boolean, style: any, segment: Segment) {
    return (
        <SegmentsTableRow sx={{padding: 0, margin: 0,}}
                          isEditing={isEditing}
                          isLocked={isLocked}
                          style={{...style}}
                          direction={"row"}
        >
            <SegmentCell sx={{minWidth:'35px', width: '35px', position: 'relative', display:"flex",paddingLeft: '0 !important', alignItems: 'flex-start'}}>
                <Checkbox id={`segment-selected-checkbox-${position}`} sx={{padding: 0}}/>
            </SegmentCell>
            <SegmentCell style={{paddingLeft: 20, margin: 0, maxWidth: '50px', whiteSpace: 'nowrap',}}>
                <Stack>
                    <Typography textAlign={"left"} sx={{padding: 0, margin: 0,}}>{segment.order + 1}</Typography>
                </Stack>
            </SegmentCell>
            <SegmentCell sx={{padding: 0, margin: 0,}} style={{width: '100%'}}>
                <LinearProgress sx={{width: '100%'}}/>
            </SegmentCell>
        </SegmentsTableRow>
    );
}

interface IHotkeyContext {
    keyEvent: React.KeyboardEvent
    segmentViewProps: SegmentViewProps,
    sourceText: string,
    targetText: string,
    editor: Editor
}

interface ISegmentHotkeys {
    isHotkey: (e: React.KeyboardEvent) => boolean
    action: (context: IHotkeyContext) => Promise<void>
}

const hotKeys = List<ISegmentHotkeys>(
    [
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && e.key === 'Enter',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const text = context.targetText;
                if (text === "")
                    return;

                const result = await approveTranslationAction(props.segment, text);
                if (result)
                    props.onShiftSegment(1);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && (e.key === 'Insert' || e.code === "KeyI"),
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const editor = context.editor;
                const segment = props.segment;
                await saveTranslationAction(segment,context.sourceText,null,editor.history)
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.ctrlKey && /^[1-9]$/.test(e.key),
            action: async (context: IHotkeyContext) => {
                const id = parseInt(context.keyEvent.key) - 1;
                const tmTranslation = segmentStore.getState().searchResults.list.get(id);
                if (!tmTranslation)
                    return;

                const props = context.segmentViewProps;
                const editor = context.editor;
                await updateTranslationFromResourceAction(props.segment, tmTranslation, editor)
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'Enter',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                const text = context.targetText;
                if (text !== "") {
                    await saveTranslationAction(
                        props.segment,
                        text,
                        null,
                        null);
                }
                props.onShiftSegment(1);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'ArrowUp',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                props.onShiftSegment(-1);
            }
        },
        {
            isHotkey: (e: React.KeyboardEvent) => e.key === 'ArrowDown',
            action: async (context: IHotkeyContext) => {
                const props = context.segmentViewProps;
                props.onShiftSegment(1);
            }
        }
    ]);
