import {Segment} from "../../../model/Segment";
import {Page} from "../../../model/Page";
import GlossaryPairSearchResult from "../../../model/GlossaryPairSearchResult";
import TranslationMemorySearchResult from "../../../model/TranslationMemorySearchResult";
import {Editor} from "slate";

export enum SegmentPayloadAction {
    SetSearchResults = "SegmentPayloadAction.SetSearchResults",
    SetGlossaryPairs = "SegmentPayloadAction.SetGlossaryPairs",
    SelectGlossaryPair = "SegmentPayloadAction.SelectGlossaryPair",
    SetSegment = "SegmentPayloadAction.SetSegment",
    SetTargetEditor = "SegmentPayloadAction.SetTargetEditor",
    SetCursorPosition = "SegmentPayloadAction.SetCursorPosition",
    SelectTMSuggest = "SegmentPayloadAction.SelectTMSuggest"
}

interface ISetSearchResultsPayload {
    action: SegmentPayloadAction.SetSearchResults,
    searchResults: Page<TranslationMemorySearchResult>
}

interface ISetGlossaryPairsPayload {
    action: SegmentPayloadAction.SetGlossaryPairs,
    glossaryPairs: Page<GlossaryPairSearchResult>
}

interface ISetSegmentPayload {
    action: SegmentPayloadAction.SetSegment,
    segment: Segment
}

interface ISelectGlossaryPairPayload {
    action: SegmentPayloadAction.SelectGlossaryPair,
    glossaryPair: GlossaryPairSearchResult | null
}

interface ISetTargetEditorPayload {
    action: SegmentPayloadAction.SetTargetEditor,
    targetEditor: Editor | null
}

interface ISetCursorPositionPayload {
    action: SegmentPayloadAction.SetCursorPosition,
    cursorPosition: number | null
}

interface ISelectTMSuggestPayload {
    action: SegmentPayloadAction.SelectTMSuggest,
    tmSuggest: TranslationMemorySearchResult | null
}

export type SegmentEditorPayload = ISetSearchResultsPayload
    | ISetSegmentPayload
    | ISetGlossaryPairsPayload
    | ISelectGlossaryPairPayload
    | ISelectTMSuggestPayload
    | ISetTargetEditorPayload
    | ISetCursorPositionPayload;

export function setSearchResults(searchResults: Page<TranslationMemorySearchResult>): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetSearchResults,
        searchResults: searchResults
    }
}

export function setGlossaryPairs(glossaryPairs: Page<GlossaryPairSearchResult>): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetGlossaryPairs,
        glossaryPairs: glossaryPairs
    }
}

export function setSegment(segment: Segment)
    : SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetSegment,
        segment: segment
    }
}

export function setTargetEditor(editor: Editor): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetTargetEditor,
        targetEditor: editor
    }
}

export function setCursorPosition(cursorPosition: number | null): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SetCursorPosition,
        cursorPosition: cursorPosition
    }
}

export function selectGlossaryPair(searchResult: GlossaryPairSearchResult | null): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SelectGlossaryPair,
        glossaryPair: searchResult
    }
}

export function selectTMSuggest(tmSuggest: TranslationMemorySearchResult | null): SegmentEditorPayload {
    return {
        action: SegmentPayloadAction.SelectTMSuggest,
        tmSuggest: tmSuggest
    }
}