import {List, Set} from "immutable";
import TranslationMemory, {TranslationMemoryGroup} from "../model/TranslationMemory";
import axios, {AxiosResponse} from "axios";
import {
    buildUrl,
    dispatchError,
    dispatchFetchPageError,
    MultipartFormDataConfig,
    toParameters
} from "../globals/ApiGlobals";
import {
    ITranslationMemoryBuildResponse,
    ITranslationMemoryResponse,
    toTranslationMemoryBuildModel,
    toTranslationMemoryModel
} from "./model/TranslationMemoryApiModel";
import {Page} from "../model/Page";
import {Pageable} from "../model/Pageable";
import TranslationMemoryFilter from "../flux/translation-memory/TranslationMemoryFilter";
import {EmbeddedResponseWithPagination} from "../globals/ApiModels";
import {Build} from "../model/Build";

export function fetchTranslationMemoryByName(name: string) {
    const filter = new TranslationMemoryFilter({name: name, nameOperator: 'equals'});
    const url = buildUrl(
        endpoint + "/search",
        {},
        toParameters<TranslationMemoryFilter>(filter));
    return axios
        .get(url)
        .then((response: AxiosResponse<EmbeddedResponseWithPagination<ITranslationMemoryResponse[]>>) => {
            const found = response.data._embedded;
            if (found.length === 0)
                return null;
            return toTranslationMemoryModel(found[0]);
        })
        .catch(error => {
            dispatchError(error);
            return null;
        });
}

export function fetchTranslationMemories(filter?: TranslationMemoryFilter,
                                         page?: Pageable) {
    const url = buildUrl(endpoint + "/search", {}, toParameters<TranslationMemoryFilter>(filter, page));

    return axios
        .get(url)
        .then((response:
               AxiosResponse<EmbeddedResponseWithPagination<ITranslationMemoryResponse[]>>) => {
            const list = response.data._embedded.map(toTranslationMemoryModel);
            return Page.fromBackendPage<TranslationMemory>(response.data.page).setList(List(list))
        })
        .catch(dispatchFetchPageError<TranslationMemory>);
}

export function uploadTranslationMemory(tm: TranslationMemory, tmFile: File) {
    if (tm.id)
        return updateTranslationMemory(tm, tmFile);
    return axios
        .post(endpoint + "/upload",
            {
                file: tmFile,
                name: tm.name
            },
            MultipartFormDataConfig)
        .then((response: AxiosResponse<ITranslationMemoryResponse>) =>
            toTranslationMemoryModel(response.data))
        .catch(error => {
            dispatchError(error);
            return null;
        });
}

export function updateTranslationMemory(tm: TranslationMemory, tmFile: File) {
    return axios
        .post(endpoint + "/update",
            {
                file: tmFile,
                id: tm.id,
                name: tm.name
            },
            MultipartFormDataConfig)
        .then((response: AxiosResponse<ITranslationMemoryResponse>) =>
            toTranslationMemoryModel(response.data))
        .catch(error => {
            dispatchError(error);
            return null;
        });
}

export async function createTranslationMemory(tm: TranslationMemory) {
    return axios
        .post(endpoint + "/create", {
            name: tm.name,
            sourceLanguage: tm.sourceLanguage?.id,
            targetLanguages: tm.targetLanguages.map(language => language.id).toArray()
        })
        .then((response: AxiosResponse<ITranslationMemoryResponse>) =>
            toTranslationMemoryModel(response.data))
        .catch(error => {
            dispatchError(error);
            return new TranslationMemory();
        })
}

export function deleteTranslationMemories(memories: Set<TranslationMemory>) {
    return Promise
        .all(memories.map(value => deleteTranslationMemory(value)))
        .then(() => {
        });
}

function deleteTranslationMemory(memory: TranslationMemory) {
    return axios.delete(endpoint + "/" + memory.id).catch(dispatchError)
}

export function buildTranslationMemories(memories: TranslationMemoryGroup) {
    return axios
        .post(endpoint + "/build", {
            ids: memories.translationMemories.map(tm => tm.id).toArray()
        })
        .then((response: AxiosResponse<ITranslationMemoryBuildResponse>) =>
            toTranslationMemoryBuildModel(response.data))
        .catch(error => {
            dispatchError(error);
            return new Build();
        })
}

export function buildTranslationMemory(memory: TranslationMemory) {
    return buildTranslationMemories(new TranslationMemoryGroup(Set.of(memory)));
}

const endpoint = "/api/pretranslate/translation-memory"
