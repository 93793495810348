import {Record} from "immutable";
import {Language} from "./Language";
import WorkflowStep from "./WorkflowStep";
import {StatisticMetric} from "./StatisticMetric";
import {numberFormat} from "../globals/Utils";

interface IStatistic {
    language: Language,
    workflow: WorkflowStep,
    metric: StatisticMetric

}

const StatisticsRecord = Record<IStatistic>({
    language: new Language(),
    workflow: new WorkflowStep(),
    metric: new StatisticMetric()
})

export class Statistic extends StatisticsRecord {
    public get presentationWithWorkflow() {
        return `${this.workflow.name}: ${numberFormat(this.metric.translatedWords)} /
        ${numberFormat(this.metric.totalWords)}`;
    }

    public get presentation() {
        return `${this.metric.translatedWords}/${this.metric.totalWords} words (${this.percent}%)`;
    }

    public get percent() {
        let percent = 0;
        if (this.metric.totalWords)
            percent = Math.round(10000 * this.metric.translatedWords / this.metric.totalWords) / 100;

        return percent;
    }
}