import {List} from "immutable";
import TranslationMemory, {ProjectTranslationMemory, TranslationMemoryState} from "../../model/TranslationMemory";
import {ConcordanceSearchResult} from "../../model/ConcordanceSearch";
import TranslationMemorySearchResult from "../../model/TranslationMemorySearchResult";
import {Build, BuildState} from "../../model/Build";
import {Language} from "../../model/Language";

export function toTranslationMemoryModel(response: ITranslationMemoryResponse) {
    return new TranslationMemory({
        id: response.id,
        name: response.name,
        sourceLanguage: response.sourceLanguage ? Language.fromSupported(response.sourceLanguage) : null,
        targetLanguages: List(response.targetLanguages.map(language => Language.fromSupported(language))),
        translationMemoryState: TranslationMemoryState[response.state as keyof typeof TranslationMemoryState]
    });
}

export function toProjectTranslationMemoryModel(response: IProjectTranslationMemoryResponse) {
    return new ProjectTranslationMemory({
        id: response.translationMemory.id,
        translationMemory: toTranslationMemoryModel(response.translationMemory),
        projectId: response.projectId,
        state: BuildState[response.state as keyof typeof BuildState],
        writtenSegments: response.writtenSegments,
        totalSegments: response.totalSegments,
        errors: response.errors
    })
}

export function toTranslationMemoryBuildModel(response: ITranslationMemoryBuildResponse) {
    return new Build({
        id: response.id,
        state: BuildState[response.state as keyof typeof BuildState]
    })
}

export function toSearchResultModel(response: ISearchResultResponse) {
    return new TranslationMemorySearchResult({
        translationMemory: response.tmName,
        source: response.source,
        target: response.target,
        mode: response.mode,
        scoring: response.scoring,
        diff: response.diff
    });
}

export function toConcordanceSearchResultModel(response: IConcordanceSearchResponse) {
    return new ConcordanceSearchResult({
        source: response.source,
        target: response.target
    });
}

export interface ITranslationMemoryResponse {
    id: string,
    name: string,
    sourceLanguage: string | null,
    targetLanguages: string[],
    state: string
}

export interface IProjectTranslationMemoryResponse {
    projectId: number,
    translationMemory: ITranslationMemoryResponse,
    state: string | null,
    totalSegments: number | null,
    writtenSegments: number | null,
    errors: string[] | null
}

export interface ITranslationMemoryBuildResponse {
    id: string,
    state: string
}

export interface ISearchResultResponse {
    source: string,
    target: string,
    mode: string,
    tmName: string,
    scoring: number,
    diff: string
}

export interface IConcordanceSearchResponse {
    source: string,
    target: string
}
